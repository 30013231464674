import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { RestService } from './rest.service';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { EnrollSessionService } from './enrollSession.service';
@Injectable({
  providedIn: 'root'
})
export class StepperService {
  ItemLength: any;
  SmartOrderSubItems: any;
  SmartOrderItems: any;
  SmartOrdersSubTotal: any;
  SmartOrderBVTotal: any;
  customerDetail: any;
  SmartOrder: any;
  SmartOrderDetails: any = {};
  constructor(
    private _toastr: ToastrService,
    private _router: Router,
    private _http: HttpClient,
    private route: ActivatedRoute,
    private _restService: RestService,
    private _enrollSession: EnrollSessionService) {

    const SmartOrderItems = JSON.parse(localStorage.getItem('SmartOrderItems'));
    this.customerDetail = JSON.parse(localStorage.getItem('customerDetail'));
    this.SmartOrderDetails = JSON.parse(localStorage.getItem('SmartOrderDetails')) ? JSON.parse(localStorage.getItem('SmartOrderDetails')) : {};
    this.SmartOrderItems = SmartOrderItems || [];
    this.ItemLength = SmartOrderItems ? SmartOrderItems.length : 0;
    this.SmartOrdersSubTotal = JSON.parse(localStorage.getItem('SmartOrdersSubTotal'));
    this.SmartOrderBVTotal = JSON.parse(localStorage.getItem('SmartOrderBVTotal'));
    this.SmartOrderSubItems = JSON.parse(localStorage.getItem('SmartOrderSubItems')) || [];
  }

  SetStepperPage(page: any): void {
    let stepper;
    if (this._enrollSession.data.customertype === 22) {
      stepper = 'steper1';
    }
    else {
      stepper = 'steper';
    }

    $('#' + stepper).find('span#' + page).removeClass('complete1').addClass('complete');
    if (page === 'Enroll') {
      page = 'Enrollment';
    }
    setTimeout(() => {
      $('#' + stepper).find('span#' + page).nextAll().removeClass('complete').addClass('complete1');
      $('#' + stepper).find('span#' + page).prevAll().removeClass('complete1').addClass('complete');
      $('#' + stepper).find('span#' + page).addClass('complete');
    }, 1);
    if (!$('#' + stepper).find('span#' + page).hasClass('complete1')) {
      this._router.navigate([page]);
      if (page === 'Enroll') {
        page = 'Enrollment';
      }
      setTimeout(() => {
        $('#' + stepper).find('span#' + page).nextAll().removeClass('complete').addClass('complete1');
        $('#' + stepper).find('span#' + page).prevAll().removeClass('complete1').addClass('complete');
        $('#' + stepper).find('span#' + page).addClass('complete');
      }, 1);
    }
  }

}
