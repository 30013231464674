import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { CalculateRetailOrderService } from './calculateRetailOrder.service';
import { EnrollSessionService } from './enrollSession.service';

@Injectable({
  providedIn: 'root'
})
export class CalculateRetailSmartOrderService {

  constructor(
    private _calculateRetailOrder: CalculateRetailOrderService,
    private _enrollSession: EnrollSessionService
  ) {
    this._enrollSession.data = JSON.parse(sessionStorage.getItem('EnrollSession')) ? JSON.parse(sessionStorage.getItem('EnrollSession')) : {};
  }
  calculate(shippingId: any): any {
    const promise = new Promise((resolve, reject) => {
      this._enrollSession.data.RetailOrderSummary.SmartOrderDetails = this._enrollSession.data.RetailOrderSummary.SmartOrderDetails || {};
      if (this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0) {
        this._calculateRetailOrder.calculate(this._enrollSession.data.RetailOrderSummary.SmartOrders, shippingId, (responseSmart: any) => {
          this._enrollSession.data.RetailOrderSummary.SmartOrderDetails.Total = responseSmart.Total;
          this._enrollSession.data.RetailOrderSummary.SmartOrderDetails.SubTotal = responseSmart.SubTotal;
          this._enrollSession.data.RetailOrderSummary.SmartOrderDetails.BV = responseSmart.BusinessVolumeTotal;
          this._enrollSession.data.RetailOrderSummary.SmartOrderDetails.CV = responseSmart.CommissionableVolumeTotal;
          this._enrollSession.data.RetailOrderSummary.SmartOrderDetails.Tax = responseSmart.TaxTotal;
          this._enrollSession.data.RetailOrderSummary.SmartOrderDetails.ShippingTotal = responseSmart.ShippingTotal;
          this._enrollSession.data.SmartOrderProductDetail = responseSmart.CustomDetails;
          resolve(responseSmart);
        }, 4);
      } else {
        this._enrollSession.data.RetailOrderSummary.SmartOrderDetails.Total = 0;
        this._enrollSession.data.RetailOrderSummary.SmartOrderDetails.SubTotal = 0;
        this._enrollSession.data.RetailOrderSummary.SmartOrderDetails.BV = 0;
        this._enrollSession.data.RetailOrderSummary.SmartOrderDetails.CV = 0;
        this._enrollSession.data.RetailOrderSummary.SmartOrderDetails.Tax = 0;
        this._enrollSession.data.RetailOrderSummary.SmartOrderDetails.ShippingTotal = 0;
        this._enrollSession.data.SmartOrderProductDetail = {};
      }
    });
    return promise;
  }
}
