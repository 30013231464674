import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { EnrollSessionService } from '../enrollSession.service';
import { BASE_URL } from 'app/constants';
import { Router } from '@angular/router';
import { RestService } from '../rest.service';
import { AuthService } from '../auth.service';




@Component({
    selector: 'app-lifestyleSubscription',
    templateUrl: './LifestyleSubscription.component.html',
    styleUrls: ['./LifestyleSubscription.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class LifestyleSubscriptionComponent implements OnInit {
    currentRouterPath: any;
  public userLoginDialog: boolean = false;
  public userInfo: any = {};
  public loginDetails: any = {};
  /**
    * Constructor
    *
    * @param {FuseConfigService} _fuseConfigService
    * @param {FormBuilder} _formBuilder
    */
  constructor(
    public dialogRef: MatDialogRef<LifestyleSubscriptionComponent>,
    public _enrollSession: EnrollSessionService,
    public router: Router,
    public authService:AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _toastr: ToastrService, public _restService: RestService) {
    this.currentRouterPath = this.router.url.split('?')[0];
  }

  ngOnInit(): void {

  }



  onClickYes()
  {
    if (this.data.item=='11200' || this.data.item=='11205') {
      window.location.href = "https://smartorder.ultlifestyle.com/OrderReview?itemcode=11240&site=ultlifestyle"
    }
    else if (this.data.item=='11210' || this.data.item=='11220')
    {
      window.location.href = "https://smartorder.ultlifestyle.com/OrderReview?itemcode=11241&site=ultlifestyle"
    }
    else if (this.data.item=='11208' || this.data.item=='11209')
    {
      window.location.href = "https://smartorder.ultlifestyle.com/OrderReview?itemcode=11242&site=ultlifestyle"
    }
    else if (this.data.item=='11235' || this.data.item=='11238')
    {
      window.location.href = "https://smartorder.ultlifestyle.com/OrderReview?itemcode=11243&site=ultlifestyle"
    }
    this.dialogRef.close();
  }

}
