<div class="dialog-content-wrapper" id="two-way-wrapper" *ngIf="currentRouterPath == '/TodaysOrder'">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
    </mat-toolbar>
    <div style="display:flex" class="pop-up-background">
      <div class="pop-up-div">
        <h2 class="pop-up-headline">Would you like to Upgrade
            <br> your ULT Pack? 
            <br> Get a free portable electric blender 
            <br> when you sign-up with a  
            <br> Lifestyle Subscription! </h2>
        <div class="pop-up-button-div">
            <button mat-button aria-current="page" class="pop-up-button w-button w--current"
            cdkFocusInitial (click)="onClickYes()">YES</button> &nbsp;
          <button mat-button class="pop-up-button pop-up-button-no w-button" [mat-dialog-close]="true">NO</button>
        </div>
      </div>
    </div>
  </div>
  
  
  