import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
// import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import * as _ from 'underscore';
// import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgpSortModule } from 'ngp-sort-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { SubscribeComponent } from './Subscribe.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SummaryCartModule } from '../summary-cart/summary-cart.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from '../pipe/pipe.module';
import { StepperModule } from '../stepper/stepper.module';

const routes: Routes = [
    {
        path: 'Subscribe',
        component: SubscribeComponent
    }
];

@NgModule({
    declarations: [
        SubscribeComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        // MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        // MatSelectModule,
        MatTableModule,
        MatTabsModule,
        NgpSortModule,
        NgxChartsModule,
        FilterPipeModule,
        // MatSlideToggleModule,
        MatDialogModule,
        MatExpansionModule,
        SummaryCartModule,
        FlexLayoutModule,
        PipesModule,
        StepperModule

    ], entryComponents: [
        
    ],
    providers: [
    ],
    exports: [
    ]
})
export class SubscribeModule {
}

