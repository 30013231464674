<!-- <button mat-icon-button class="close-button" [mat-dialog-close]="true">
	<mat-icon class="close-icon" color="warn">close</mat-icon>
</button> -->

<mat-dialog-content class="mat-typography">
	<p>
		You currently have item(s) in your cart that are not available for your shipping address. The item(s) will be
		removed from your cart. Do you want to Proceed to checkout?
	</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
		<button mat-raised-button color="primary" (click)="Action('Yes')" tabindex="1">YES</button>
		<button mat-raised-button (click)="Action('No')" tabindex="-1">NO</button>
</mat-dialog-actions>