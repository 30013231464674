<div  class="checkout-header-div checkout-header-div-mobile" fxLayoutAlign="start start">
  <a [routerLink]="steps[0].stepper_back"  class="back-arrow-link-block w-inline-block" aria-label="order-stepper"><img
      src="assets/images/image/Back-Arrow.svg" loading="lazy" alt="image" class="back-arrow-svg"></a>
      <div class="progress-bar-div1" fxLayout="column" fxLayoutAlign="center none">
        <div  *ngFor="let step of steps;" >
         <div fxLayout="row" fxLayoutAlign="start none" fxLayoutAlign.gt-sm="start center" fxLayoutAlign.xs="start center" fxFlex="100" *ngFor="let title of step.stepper_title; let i = index">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="31" >
            <div fxFlex="30" fxLayoutAlign="center center">
              <a [routerLink]="step.routes[i]"><img [src]="step.stepper_images[i]" loading="lazy" alt="image"></a>
            </div>
            <div fxFlex="70" fxLayoutAlign="center center">
              <span class="f-s-8">{{title}}</span>
            </div>
          </div>
          <div fxFlex="69" class="border-top" fxLayoutAlign="center center" *ngIf="hide(i)" >
          </div>
         </div>
          <!--<div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
            <div fxFlex="30">
              <a routerLink="../TodaysOrder"><img [src]="stepper_images[0]" loading="lazy"></a>
            </div>
            <div fxFlex="70">
              <span class="f-s-8">TODAY</span>
            </div>
          </div>
          <div fxFlex="15" class="border-top" fxLayoutAlign="start center">
          </div>
           <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
            <div fxFlex="30">
              <a routerLink="../Enhance"><img [src]="stepper_images[1]" loading="lazy"></a>
            </div>
            <div fxFlex="70">
              <span class="f-s-8">ENHANCE</span>
            </div>
          </div>
          <div fxFlex="15" class="border-top" fxLayoutAlign="start center">
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
            <div fxFlex="30">
              <a><img  [src]="stepper_images[2]" loading="lazy"></a>
            </div>
            <div fxFlex="70">
              <span class="f-s-8">SUBSCRIBE</span>
            </div>
          </div>
          <div fxFlex="15" class="border-top" fxLayoutAlign="start center">
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
            <div fxFlex="30">
              <a><img  [src]="stepper_images[3]" loading="lazy"></a>
            </div>
            <div fxFlex="70">
              <span class="f-s-8">SHIPPING</span>
            </div>
          </div>
          <div fxFlex="15" class="border-top"  fxLayoutAlign="start center">
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
            <div fxFlex="30">
              <a><img  [src]="stepper_images[4]" loading="lazy"></a>
            </div>
            <div fxFlex="70">
              <span class="f-s-8">CHECKOUT</span>
            </div>
          </div> -->
        </div>
      </div>
</div>
<!-- <div class="checkout-header-div" *ngIf="!_enrollSession.mobileView">
  <a routerLink="../TodaysOrder" class="back-arrow-link-block w-inline-block"><img
      src="assets/images/image/Back-Arrow.svg" loading="lazy" alt="image" class="back-arrow-svg"></a>
      <div class="progress-bar-div" fxLayout="column" fxLayoutAlign="center none">
        <div fxLayout="row" fxLayoutAlign="start none" fxLayoutAlign.gt-sm="start center" fxFlex="100">
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
            <div fxFlex="30">
              <a routerLink="../TodaysOrder"><img  [src]="stepper_images[0]" loading="lazy"></a>
            </div>
            <div fxFlex="70">
              <span class="f-s-8">TODAY</span>
            </div>
          </div>
          <div fxFlex="15" class="border-top" fxLayoutAlign="start center">
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
            <div fxFlex="30">
              <a routerLink="../Enhance"><img  [src]="stepper_images[1]" loading="lazy"></a>
            </div>
            <div fxFlex="70">
              <span class="f-s-8">ENHANCE</span>
            </div>
          </div>
          <div fxFlex="15" class="border-top" fxLayoutAlign="start center">
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
            <div fxFlex="30">
              <a><img  [src]="stepper_images[2]" loading="lazy"></a>
            </div>
            <div fxFlex="70">
              <span class="f-s-8">SUBSCRIBE</span>
            </div>
          </div>
          <div fxFlex="15" class="border-top" fxLayoutAlign="start center">
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
            <div fxFlex="30">
              <a><img  [src]="stepper_images[3]" loading="lazy"></a>
            </div>
            <div fxFlex="70">
              <span class="f-s-8">SHIPPING</span>
            </div>
          </div>
          <div fxFlex="15" class="border-top"  fxLayoutAlign="start center">
          </div>
          <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
            <div fxFlex="30">
              <a><img  [src]="stepper_images[4]" loading="lazy"></a>
            </div>
            <div fxFlex="70">
              <span class="f-s-8">CHECKOUT</span>
            </div>
          </div>
        </div>
      </div>  
</div> -->
<!-- <div class="checkout-header-div" >
    <a routerLink="../TodaysOrder" class="back-arrow-link-block w-inline-block"><img
        src="assets/images/image/Back-Arrow.svg" loading="lazy" alt="image" class="back-arrow-svg"></a>
        <div class="progress-bar-div" fxLayout="column" fxLayoutAlign="center none">
          <div fxLayout="row" fxLayoutAlign="start none" fxLayoutAlign.gt-sm="start center" fxFlex="100">
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
              <div fxFlex="30">
                <a routerLink="../TodaysOrder"><img [src]=stepper_images[0] loading="lazy"></a>
              </div>
              <div fxFlex="70">
                <span class="f-s-8">TODAY</span>
              </div>
            </div>
            <div fxFlex="15" class="border-top" fxLayoutAlign="start center">
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
              <div fxFlex="30">
                <a routerLink="../Enhance"><img [src]="stepper_images[1]" loading="lazy"></a>
              </div>
              <div fxFlex="70">
                <span class="f-s-8">ENHANCE</span>
              </div>
            </div>
            <div fxFlex="15" class="border-top" fxLayoutAlign="start center">
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
              <div fxFlex="30">
                <a><img [src]="stepper_images[2]" loading="lazy"></a>
              </div>
              <div fxFlex="70">
                <span class="f-s-8">SUBSCRIBE</span>
              </div>
            </div>
            <div fxFlex="15" class="border-top" fxLayoutAlign="start center">
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
              <div fxFlex="30">
                <a><img [src]="stepper_images[3]" loading="lazy"></a>
              </div>
              <div fxFlex="70">
                <span class="f-s-8">SHIPPING</span>
              </div>
            </div>
            <div fxFlex="15" class="border-top"  fxLayoutAlign="start center">
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
              <div fxFlex="30">
                <a><img [src]="stepper_images[4]" loading="lazy"></a>
              </div>
              <div fxFlex="70">
                <span class="f-s-8">CHECKOUT</span>
              </div>
            </div>
          </div>
        </div>  
  </div> -->