<div class="page-wrapper">
  <div class="ult-checkout-section wf-section">
    <app-stepper *ngIf="_enrollSession.mobileView"></app-stepper>
    <!-- <div *ngIf="_enrollSession.mobileView" class="checkout-header-div checkout-header-div-mobile" fxLayoutAlign="center center">
        <a routerLink="../Subscribe" class="back-arrow-link-block w-inline-block"><img src="assets/images/image/Back-Arrow.svg" loading="lazy" alt="" class="back-arrow-svg"></a>
        <div class="progress-bar-div" fxLayout="column" fxLayoutAlign="center none">
          <div fxLayout="row" fxLayoutAlign="start none" fxLayoutAlign.gt-sm="start center" fxLayoutAlign.xs="start center" fxFlex="100">
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
              <div fxFlex="30">
                <a routerLink="../TodaysOrder"><img src="assets/images/stepper-logo/Circle-Check.svg" loading="lazy"></a>
              </div>
              <div fxFlex="70">
                <span class="f-s-8">TODAY</span>
              </div>
            </div>
            <div fxFlex="15" class="border-top" fxLayoutAlign="start center">
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
              <div fxFlex="30">
                <a routerLink="../Enhance"><img src="assets/images/stepper-logo/Circle-Check.svg" loading="lazy"></a>
              </div>
              <div fxFlex="70">
                <span class="f-s-8">ENHANCE</span>
              </div>
            </div>
            <div fxFlex="15" class="border-top" fxLayoutAlign="start center">
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
              <div fxFlex="30">
                <a routerLink="../Subscribe"><img src="assets/images/stepper-logo/Circle-Check.svg" loading="lazy"></a>
              </div>
              <div fxFlex="70">
                <span class="f-s-8">SUBSCRIBE</span>
              </div>
            </div>
            <div fxFlex="15" class="border-top" fxLayoutAlign="start center">
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
              <div fxFlex="30">
                <a routerLink="../Shipping"><img src="assets/images/stepper-logo/Step-4-Green.svg" loading="lazy"></a>
              </div>
              <div fxFlex="70">
                <span class="f-s-8">SHIPPING</span>
              </div>
            </div>
            <div fxFlex="15" class="border-top"  fxLayoutAlign="start center">
            </div>
            <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
              <div fxFlex="30">
                <a><img src="assets/images/stepper-logo/Step-5-Gray.svg" loading="lazy"></a>
              </div>
              <div fxFlex="70">
                <span class="f-s-8">CHECKOUT</span>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    <summary-cart *ngIf="_enrollSession.mobileView" [routPath]="routPath" [customerInfoForm]="customerInfoForm"
      [shippingForm]="shippingForm" [shippingMethodForm]="shippingMethodForm"></summary-cart>
    <div class="checkout-div">
      <app-stepper *ngIf="!_enrollSession.mobileView"></app-stepper>
      <!-- <div class="checkout-header-div" *ngIf="!_enrollSession.mobileView">
          <a routerLink="../Subscribe" class="back-arrow-link-block w-inline-block"><img src="assets/images/image/Back-Arrow.svg" loading="lazy" alt="" class="back-arrow-svg"></a>
          <div class="progress-bar-div" fxLayout="column" fxLayoutAlign="center none">
            <div fxLayout="row" fxLayoutAlign="start none" fxLayoutAlign.gt-sm="start center" fxFlex="100">
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
                <div fxFlex="30">
                  <a routerLink="../TodaysOrder"><img src="assets/images/stepper-logo/Circle-Check.svg" loading="lazy"></a>
                </div>
                <div fxFlex="70">
                  <span class="f-s-8">TODAY</span>
                </div>
              </div>
              <div fxFlex="15" class="border-top" fxLayoutAlign="start center">
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
                <div fxFlex="30">
                  <a routerLink="../Enhance"><img src="assets/images/stepper-logo/Circle-Check.svg" loading="lazy"></a>
                </div>
                <div fxFlex="70">
                  <span class="f-s-8">ENHANCE</span>
                </div>
              </div>
              <div fxFlex="15" class="border-top" fxLayoutAlign="start center">
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="5">
                <div fxFlex="30">
                  <a routerLink="../Subscribe"><img src="assets/images/stepper-logo/Circle-Check.svg" loading="lazy"></a>
                </div>
                <div fxFlex="70">
                  <span class="f-s-8">SUBSCRIBE</span>
                </div>
              </div>
              <div fxFlex="15" class="border-top" fxLayoutAlign="start center">
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
                <div fxFlex="30">
                  <a routerLink="../Shipping"><img src="assets/images/stepper-logo/Step-4-Green.svg" loading="lazy"></a>
                </div>
                <div fxFlex="70">
                  <span class="f-s-8">SHIPPING</span>
                </div>
              </div>
              <div fxFlex="15" class="border-top"  fxLayoutAlign="start center">
              </div>
              <div fxLayout="column" fxLayoutAlign="center center" fxFlex="10" fxFlex.gt-md="7">
                <div fxFlex="30">
                  <a><img src="assets/images/stepper-logo/Step-5-Gray.svg" loading="lazy"></a>
                </div>
                <div fxFlex="70">
                  <span class="f-s-8">CHECKOUT</span>
                </div>
              </div>
            </div>
          </div> 
        </div> -->
      <ng-container
        *ngIf="this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length>0">
        <div>
            <h1 class="checkout-h1 shipping-h1">Choose a Process Type</h1>
        </div>
        <div class="radio_button_custom">
        <mat-radio-group [(ngModel)]="SubscriptionProcessType" fxLayout="row" fxLayoutAlign="space-between center" class="process_radio">
            <mat-radio-button value="AutoOrderProcessTy1" (click)="processTypeChange('AutoOrderProcessTy1')">ALWAYS SHIP
            </mat-radio-button>
            <mat-radio-button value="AutoOrderProcessTy2" (click)="processTypeChange('AutoOrderProcessTy2')">BACKUP ORDER
            </mat-radio-button>
        </mat-radio-group>
       </div>
        <div>
            <p>If you choose ALWAYS SHIP - your monthly subscription order will Always Ship on your Smart Order date.</p>
            <p>If you choose BACKUP ORDER - If you place another order before your Smart Order date, your Smart Order will not be processed for that current month. (Your Smart order Subscription will remain Active for future months).</p>
        </div>
        <h1 class="checkout-h1 shipping-h1">Choose A Date</h1>
        <p class="checkout-p">Smart Orders process the 1st through the 21st of each month and ship 1-4 business days
          after the process date.<br>
          <!-- <br>If you place another order before your Smart Order date, your Smart Order will not be processed for that
          current month. (Your Smart order Subscription will remain Active for future months) -->
        </p>
        <div>
          <div>
            <div style="color: #016145; font-weight: bold; margin: 10px;" class="btn btn-outline-secondary"
              mwlCalendarToday [(viewDate)]="viewDate">
              <span class="title">
                {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
              </span>
            </div>
            <!-- <div style="text-align: right;">
              <button class="btn btn-primary" (click)="decrement()" [disabled]="prevBtnDisabled">
                 <mat-icon>arrow_left</mat-icon>
              </button>
            
              <button [disabled]="nextBtnDisabled" style="text-align: right;" class="btn btn-primary" (click)="increment()">
                <mat-icon>arrow_right</mat-icon>
              </button>
            </div> -->
          </div>
          <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [refresh]="refresh"
              (dayHeaderClicked)="dayHeaderClicked.emit($event)" (dayClicked)="dayClicked($event.day)"
              (beforeViewRender)="beforeMonthViewRender($event)">
            </mwl-calendar-month-view>
          </div>
        </div>
        <div class="smartOrderDateBox">
          <div class="p-r-10">
            <label>Your Smart Order Date:</label>
          </div>
          <div>
            <Span>{{_enrollSession.data.smartOrderDate ? _enrollSession.data.smartOrderDate : '--/--/----'}}</Span>
          </div>
        </div>
        <div class="credit-card-message-div">
          <div class="credit-card-message-icon-div p-5"><img src="assets/images/image/Notice-Icon.svg" loading="lazy"
              alt="" class="notice-icon"></div>
          <div class="credit-card-message-text-div p-10">
            <div class="checkout-p credit-card-message-text">
              You have chosen to receive a monthly shipment of products. The shipping and billing information that you
              are using for your initial order will be the default method for your Smart Order. You can update this at
              any time by logging into your Smart Office.<br>
              Your credit card will only be charged today for today's order. Your credit card will <strong>NOT</strong>
              be charged today for your Monthly Subscription order. Your credit card will only be charged for your
              Monthly Subscription Order on the date you choose above.
            </div>
          </div>
        </div>
      </ng-container>
      <h1 class="checkout-h1 shipping-h1">Customer Information</h1>
      <div class="w-form">
        <form [formGroup]="customerInfoForm" id="email-form1" name="email-form" class="customer-info-form custom">
          <div fxLayout="column">
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
              <div fxLayout="column" fxFlex="49">
                <div>
                  <input type="text" class="form-field w-input" maxlength="256" name="First-Name"
                    formControlName="FirstName" placeholder="First Name*" id="First-Name" required>
                </div>
                <span class="help-block"
                  *ngIf="!customerInfoForm.get('FirstName')?.valid&&customerInfoForm.get('FirstName')?.touched">
                  <span *ngIf="customerInfoForm.get('FirstName').errors['required']">This field is required</span>
                </span>
              </div>
              <div fxLayout="column" fxFlex="49">
                <div>
                  <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
                    formControlName="LastName" placeholder="Last Name*" id="Last-Name-2" required>
                </div>
                <span class="help-block"
                  *ngIf="!customerInfoForm.get('LastName')?.valid&&customerInfoForm.get('LastName')?.touched">
                  <span *ngIf="customerInfoForm.get('LastName').errors['required']">This field is required</span>
                </span>
              </div>
            </div>

            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
              <div fxLayout="column" fxFlex="49">
                <div>
                  <input type="email" class="form-field w-input" maxlength="256" name="Email"
                    placeholder="Email Address*" id="Email-2" formControlName="Email" required
                    (focusout)="_enrollSession.data.RetailOrderSummary.customerInfoData.ConfirmEmail?EmailRegistered(_enrollSession.data.RetailOrderSummary.customerInfoData.Email):''">
                </div>
                <span class="help-block"
                  *ngIf="!customerInfoForm.get('Email').valid&&customerInfoForm.get('Email').touched">
                  <span
                    *ngIf="!customerInfoForm.get('Email').errors['required'] && !customerInfoForm.get('Email').valid">E-mail
                    is invalid</span>
                  <span *ngIf="customerInfoForm.get('Email').errors['required']">This field is required</span>
                </span>
              </div>
              <div fxLayout="column" fxFlex="49">
                <div>
                  <input type="email" class="form-field w-input" maxlength="256" name="ConfirmEmail"
                    placeholder="ConfirmEmail Address*" id="ConfirmEmail-2" formControlName="ConfirmEmail" required
                    (focusout)="_enrollSession.data.RetailOrderSummary.customerInfoData.Email?EmailRegistered(_enrollSession.data.RetailOrderSummary.customerInfoData.ConfirmEmail):''">
                </div>
                <span class="help-block"
                  *ngIf="!customerInfoForm.get('ConfirmEmail').valid&&customerInfoForm.get('ConfirmEmail').touched">
                  <span *ngIf="customerInfoForm.get('ConfirmEmail').errors['EmailMismatch']">E-mail do not
                    Match</span>
                  <span *ngIf="customerInfoForm.get('ConfirmEmail').errors['required']">This field is required</span>
                </span>
              </div>
            </div>

            <input type="text" class="form-field w-input" maxlength="256" name="Phone" placeholder="Phone Number*"
              id="Phone" formControlName="Phone" required>
            <span class="help-block"
              *ngIf="!customerInfoForm.get('Phone').valid&&customerInfoForm.get('Phone').touched">
              <span *ngIf="customerInfoForm.get('Phone').errors['required']">This field is required</span>
              <span
                *ngIf="!customerInfoForm.get('Phone').valid && !customerInfoForm.get('Phone').errors['required']">Phone
                Number is invalid</span>
            </span>
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
              <div fxLayout="column" fxFlex="49">
                <input type="password" class="form-field w-input" maxlength="40" name="Password" placeholder="Password*"
                  id="Password-2" formControlName="Password" required>
                <span class="help-block"
                  *ngIf="!customerInfoForm.get('Password').valid&&customerInfoForm.get('Password').touched">
                  <span *ngIf="customerInfoForm.get('Password').errors['required']">This field is required</span>
                  <span *ngIf="customerInfoForm.get('Password').errors['passwordLength']">Password length not less than
                    6 digit!</span>
                </span>
              </div>
              <div fxLayout="column" fxFlex="49">
                <input type="password" class="form-field w-input" maxlength="40" name="Confirm-Password"
                  placeholder="Confirm Password*" id="Confirm-Password-2" formControlName="ConfirmPassword" required>
                <span class="help-block"
                  *ngIf="!customerInfoForm.get('ConfirmPassword').valid&&customerInfoForm.get('ConfirmPassword').touched">
                  <span *ngIf="customerInfoForm.get('ConfirmPassword').errors['passwordMismatch']">Password do not
                    Match</span>
                  <span *ngIf="customerInfoForm.get('ConfirmPassword').errors['required']">This field is required</span>
                </span>
              </div>
            </div>
            <label>
              <span class="labelFontWeight checkbox-label w-form-label m-t-10"><b>Keep me updated on new and exclusive
                  offers.</b></span>
            </label>
            <label class="w-checkbox">
              <input type="checkbox" id="smsOptIn" name="smsOptIn" class="w-checkbox-input" formControlName="smsOptIn">
              <span class="checkbox-label w-form-label">I want to receive text communications.</span>
            </label>
            <label class="w-checkbox">
              <input type="checkbox" id="emailOptIn" name="emailOptIn" class="w-checkbox-input"
                formControlName="emailOptIn">
              <b><span class="checkbox-label w-form-label">I want to receive e-mail communications.</span></b>
            </label>
          </div>
        </form>
      </div>
      <h1 class="checkout-h1 shipping-h1">Shipping Address</h1>
      <div class="w-form">
        <form [formGroup]="shippingForm" id="email-form2" name="email-form" class="shipping-info-form">
          <div fxLayout="column">
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
              <div fxLayout="column" fxFlex="49">
                <div>
                  <input type="text" class="form-field w-input" maxlength="256" name="First-Name"
                    formControlName="firstName" placeholder="First Name*" id="First-Name" required>
                </div>
                <div class="error-box">
                  <span class="help-block"
                    *ngIf="!shippingForm.get('firstName').valid&&shippingForm.get('firstName').touched">
                    <span *ngIf="shippingForm.get('firstName').errors['required']">This field is required</span>
                  </span>
                </div>
              </div>
              <div fxLayout="column" fxFlex="49">
                <div>
                  <input type="text" class="form-field  w-input" maxlength="256" name="Last-Name"
                    formControlName="lastName" placeholder="Last Name*" id="Last-Name-2" required>
                </div>
                <div class="error-box">
                  <span class="help-block p-l-10"
                    *ngIf="!shippingForm.get('lastName').valid&&shippingForm.get('lastName').touched">
                    <span *ngIf="shippingForm.get('lastName').errors['required']">This field is required</span>
                  </span>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxFlex="100">
              <div fxLayout="column" fxFlex="100">
                <div>
                  <input type="text" class="form-field w-input" maxlength="256" name="Address-1"
                    formControlName="address1" placeholder="Address 1*" id="Address-1" required>
                </div>
                <div class="error-box">
                  <span class="help-block"
                    *ngIf="!shippingForm.get('address1').valid&&shippingForm.get('address1').touched">
                    <span *ngIf="shippingForm.get('address1').errors['required']">This field is required</span>
                  </span>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxFlex="100">
              <input type="text" class="form-field w-input" maxlength="256" name="address-2" formControlName="address2"
                placeholder="Address 2 (Optional)" id="address-3">
            </div>
            <div fxLayout="row" fxFlex="100">
              <div fxLayout="column" fxFlex="100">
                <div>
                  <input type="text" class="form-field w-input" maxlength="256" name="City" formControlName="City"
                    placeholder="City*" id="City-2" required>
                </div>
                <div class="error-box">
                  <span class="help-block p-l-10"
                    *ngIf="!shippingForm.get('City').valid&&shippingForm.get('City').touched">
                    <span *ngIf="shippingForm.get('City').errors['required']">This field is required</span>
                  </span>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-between none">
              <div fxLayout="column" fxFlex="32">
                <div>
                  <select name="CountryCode" formControlName="CountryCode" class="form-field w-input"
                    [(ngModel)]="_enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode"
                    (change)="countryChange(_enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode)">
                    <option *ngFor="let country of countriesList" [value]='country.countryCodeField'>{{
                      country.countryNameField}} </option>
                  </select>
                </div>
                <div>
                </div>
              </div>
              <div fxLayout="column" fxFlex="32">
                <div>
                  <div>
                    <select name="State" id="" formControlName="State" class="form-field w-input"
                      [(ngModel)]="!_enrollSession.data.RetailOrderSummary.shippingFormData.State ? '' : _enrollSession.data.RetailOrderSummary.shippingFormData.State"
                      (change)="stateChange(_enrollSession.data.RetailOrderSummary.shippingFormData.State,'')">
                      <option value="" disabled selected hidden>{{_enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode ==="US" ? "select state": "select province"}}</option>
                      <option *ngFor="let state of stateList" [ngValue]='state.regionCodeField'>{{state.regionNameField}}</option>
                    </select>
                  </div>
                  <div class="error-box">
                    <span class="help-block p-l-10"
                      *ngIf="!shippingForm.get('State').valid&&shippingForm.get('State').touched">
                      <span *ngIf="shippingForm.get('State').errors['required']">This field is required</span>
                    </span>
                  </div>
                </div>
              </div>
              <div fxLayout="column" fxFlex="32">
                <div>
                  <input type="text" class="form-field  w-input" maxlength="256" name="Zip-Code"
                    formControlName="ZipCode" placeholder="Zip Code*" id="Zip-Code-2" required
                    (keyup)="regexValidateZip1($event)"
                    (focusout)="_enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode?checkCalculateOrder():''">
                </div>
                <div class="error-box">
                  <span class="error-zip" *ngIf="invalidzip1">INVALID ZIP</span>
                  <span class="help-block"
                    *ngIf="!shippingForm.get('ZipCode').valid&&shippingForm.get('ZipCode').touched">
                    <span *ngIf="shippingForm.get('ZipCode').errors['required']">This field is required</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <h1 class="checkout-h1 shipping-h1">Shipping Method For Today&#x27;s Order</h1>
      <div class="w-form">
        <form [formGroup]="shippingMethodForm" id="email-form" name="email-form3" class="shipping-method-form">
          <div *ngFor="let shipping of _enrollSession.data.ShippingMethods">
            <label class="shipping-radio-button-field w-radio m-b-1">
              <input type="radio" name="shippingType" formControlName="shippingType" value="{{shipping.ShipMethodID}}"
                class="w-form-formradioinput shipping-method-radio-button w-radio-input">
              <span class="shipping-radio-button-label w-form-label">{{shipping.Description}}</span>
              <div class="shipping-price-text">${{shipping.ShippingAmount |number : '1.2-2'}}</div>
            </label>
          </div>
        </form>
      </div>
    </div>
    <summary-cart *ngIf="!_enrollSession.mobileView" [routPath]="routPath" [customerInfoForm]="customerInfoForm"
      [shippingForm]="shippingForm" [shippingMethodForm]="shippingMethodForm"></summary-cart>
  </div>
</div>