<ng-container *ngIf="_enrollSession.mobileView">
    <div class="order-summary-div-mobile">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h1 class="order-summary-h1">ORDER SUMMARY</h1>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                <ng-container
                    *ngIf="(!_enrollSession.data.RetailOrderSummary || (!_enrollSession.data.RetailOrderSummary.Enrollpack || _enrollSession.data.RetailOrderSummary.Enrollpack.length == 0) && (!_enrollSession.data.RetailOrderSummary.InitialOrders || _enrollSession.data.RetailOrderSummary.InitialOrders.length == 0) && (!_enrollSession.data.RetailOrderSummary.SmartOrders || _enrollSession.data.RetailOrderSummary.SmartOrders.length == 0))">
                    <div class="order-summary-divider"></div>
                    <div class="order-summary-totals-div-block">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">Subtotal</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Taxes</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">---</div>
                            <div class="order-summary-text">---</div>
                            <div class="order-summary-text">---</div>
                        </div>
                    </div>
                    <div class="order-summary-divider"></div>
                    <div class="order-summary-totals-div-block">
                        <div class="order-summary-totals-text-div-block order-summary-labels">
                            <div class="order-summary-text order-summary-text-total">Your Total:</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text order-summary-text-total">---</div>
                        </div>
                    </div>
                </ng-container>
                <div class="order-summary-product-div-block"
                    *ngIf="_enrollSession.data.RetailOrderSummary && ((_enrollSession.data.RetailOrderSummary.Enrollpack && _enrollSession.data.RetailOrderSummary.Enrollpack.length > 0) || (_enrollSession.data.RetailOrderSummary.InitialOrders && _enrollSession.data.RetailOrderSummary.InitialOrders.length > 0) || (_enrollSession.data.RetailOrderSummary.SmartOrders && _enrollSession.data.RetailOrderSummary.SmartOrders.length > 0))">
                    <div class="order-label-div"   *ngIf="_enrollSession.data.RetailOrderSummary && (_enrollSession.data.RetailOrderSummary?.Enrollpack && _enrollSession.data.RetailOrderSummary?.Enrollpack.length > 0)">
                        <div class="order-label w-150">
                            <div class="order-label-text" [textContent]="siteType=='fruitsandveggie' ? 'Fruit & Veggie+ Pack' : (siteType=='collagen' ? 'Collagen Pack' : 'Cante Pack' )"></div>
                        </div>
                    </div>
                    <div
                        *ngFor="let item of _enrollSession.data.RetailOrderSummary.Enrollpack | sortBy: 'asc':'-Price'; let i = index">
                        <div class="w-layout-grid order-summary-grid">
                            <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text cursor-pointer"></div>
                            <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId"
                                src="{{item.SmallImage? item.SmallImage : 'assets/img/noimage.png'}}" loading="lazy"
                                id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                class="order-summary-img img-box">
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text cursor-pointer">{{item.Description}}</div>
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                fxLayoutAlign="start center" *ngIf="currentRouterPath !='/Confirmation' && item.ItemCode!='7800GC'">
                                <div class="input-group" fxLayout="row" fxLayoutAlign="center center" 
                                    *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                    <div class="input-group-btn">
                                        <button mat-button-icon type="button" fxLayoutAlign="center center">
                                            <mat-icon
                                                (click)="_sharedMethodService.decreaseProductQuantity(item,item.Quantity,1)">
                                                remove</mat-icon>
                                        </button>
                                    </div>
                                    <div class="input-control" fxLayoutAlign="center center">
                                        <span>{{item.Quantity}}</span>
                                    </div>
                                    <div class="input-group-btn">
                                        <button mat-button-icon type="button" fxLayoutAlign="center center">
                                            <mat-icon (click)="_sharedMethodService.ContinueRetailOrder(item,1,1);">add
                                            </mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" *ngIf="item.ItemCode=='7800GC' || (item.ParentId != item.ItemCode && item.ParentId)"
                                class="order-summary-product-text" fxLayoutAlign="center start">{{item.Quantity}}</div>
                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price"
                                *ngIf="_calculateRetailOrderService.cartSubtotal >= 200">${{item.Price |number :
                                '1.2-2'}} USD</div>
                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price"
                                *ngIf="_calculateRetailOrderService.cartSubtotal < 200">${{item.RetailPrice |number :
                                '1.2-2'}} USD</div>
                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price cursor-pointer"
                                *ngIf="currentRouterPath !='/Confirmation' && i == 0">
                                    <mat-icon (click)="removedOrder(i , item.Quantity, item.ItemCode, 'transformation')">edit</mat-icon>
                            </div>
                        </div>
                    </div>

                    <!-- Colorado Retail Delivery Fees -->
                    <div class="w-layout-grid order-summary-grid" *ngIf="_enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees?.price" style="margin-bottom: 10px;">
                        <div fxLayoutAlign="center center">
                            <img src="../../../assets/images/image/retail-delivery-fees.png" loading="lazy" id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt="" class="order-summary-img img-box delivery-fee-image">
                        </div>
                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" class="order-summary-product-text">{{ _enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees.description }}</div>

                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" class="order-summary-product-text" fxLayoutAlign="center start"> {{ _enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees.quantity }} </div>

                        <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a" class="order-summary-product-price">${{ _enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees.price |number : '1.2-2'}} USD</div>

                        <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a" class="order-summary-product-price cursor-pointer">
                        </div>
                    </div>

                </div>
                <div class="order-summary-product-div-block"
                    *ngIf="_enrollSession.data.RetailOrderSummary.InitialOrders && _enrollSession.data.RetailOrderSummary.InitialOrders.length > 0">
                    <div class="order-summary-divider" *ngIf="_enrollSession.data.RetailOrderSummary && (_enrollSession.data.RetailOrderSummary?.Enrollpack && _enrollSession.data.RetailOrderSummary?.Enrollpack.length > 0)"></div>
                    <div class="order-label-div m-t-10">
                        <div class="order-label">
                            <div class="order-label-text">Today&#x27;s Order</div>
                        </div>
                    </div>
                    <div *ngFor="let item of _enrollSession.data.RetailOrderSummary.InitialOrders; let i = index">
                        <div class="w-layout-grid order-summary-grid">
                            <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text cursor-pointer"></div>
                            <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId"
                                src="{{item.SmallImage? item.SmallImage : 'assets/img/noimage.png'}}" loading="lazy"
                                id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                class="order-summary-img img-box">
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text cursor-pointer">{{item.Description}}</div>
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text" fxLayoutAlign="center start"
                                *ngIf="currentRouterPath =='/Confirmation' || item.ItemCode == '7800GC'">
                                {{item.Quantity}}</div>
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                fxLayoutAlign="start center"
                                *ngIf="currentRouterPath !='/Confirmation' && item.ItemCode != '7800GC'">
                                <div class="input-group" fxLayout="row" fxLayoutAlign="center center"
                                    *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                    <div class="input-group-btn">
                                        <button mat-button-icon type="button" fxLayoutAlign="center center">
                                            <mat-icon
                                                (click)="_sharedMethodService.decreaseProductQuantity(item,item.Quantity,2)">
                                                remove</mat-icon>
                                        </button>
                                    </div>
                                    <div class="input-control" fxLayoutAlign="center center">
                                        <span>{{item.Quantity}}</span>
                                    </div>
                                    <div class="input-group-btn">
                                        <button mat-button-icon type="button" fxLayoutAlign="center center">
                                            <mat-icon (click)="_sharedMethodService.ContinueRetailOrder(item,1,2);">add
                                            </mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" *ngIf="item.ItemCode=='7800GC' || (item.ParentId != item.ItemCode && item.ParentId)"
                            class="order-summary-product-text" fxLayoutAlign="center start">{{item.Quantity}}</div>
                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price"
                                *ngIf="_calculateRetailOrderService.cartSubtotal >= 200">${{item.Price |number :
                                '1.2-2'}} USD</div>
                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price"
                                *ngIf="_calculateRetailOrderService.cartSubtotal < 200">${{item.RetailPrice |number :
                                '1.2-2'}} USD</div>
                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price cursor-pointer"
                                *ngIf="currentRouterPath !='/Confirmation' && item.ItemCode != '7800GC'">
                                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                        class="order-summary-product-price cursor-pointer"
                                        *ngIf="currentRouterPath !='/Confirmation'">
                                        <mat-icon
                                            (click)="removedOrder(i , item.Quantity, item.ItemCode, 'todayOrder')">close
                                        </mat-icon>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="order-summary-divider"></div>
                <div
                    *ngIf="_enrollSession.data.RetailOrderSummary.Enrollpack && _enrollSession.data.RetailOrderSummary.Enrollpack.length > 0 || _enrollSession.data.RetailOrderSummary.InitialOrders && _enrollSession.data.RetailOrderSummary.InitialOrders.length > 0  || _enrollSession.data.RetailOrderSummary.AddOnOrders && _enrollSession.data.RetailOrderSummary.AddOnOrders.length > 0">
                    <div class="order-summary-totals-div-block" *ngIf="_calculateAutoOrder.getIsCallCalculateOrder()">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">Sub Total</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Taxes</div>
                            <div class="order-summary-text">Your Total</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal |number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.ShippingTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Tax|number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total|number :
                                    '1.2-2'}} USD</span>
                            </div>
                        </div>
                    </div>
                    <div class="order-summary-totals-div-block" *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder()">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">Sub Total</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Taxes</div>
                            <div class="order-summary-text">Your Total</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal |number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.ShippingTotal
                                    |number : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Tax|number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total|number :
                                    '1.2-2'}} USD</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="_enrollSession.data.RetailOrderSummary.SmartOrders && _enrollSession.data.RetailOrderSummary.SmartOrders.length>0">
                    <div class="order-summary-divider"></div>
                    <div class="order-summary-product-div-block">
                        <div class="order-label-div">
                            <div class="order-label w-175">
                                <div class="order-label-text">Smart Order Subscription</div>
                            </div>
                            <div>
                                {{_enrollSession.data.smartOrderDate ? _enrollSession.data.smartOrderDate :
                                '--/--/----'}}
                            </div>
                        </div>
                        <div *ngFor="let item of _enrollSession.data.RetailOrderSummary.SmartOrders; let i = index">
                            <div class="w-layout-grid order-summary-grid">
                                <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                                id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text cursor-pointer"></div>
                                <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId" src="{{item.SmallImage}}" loading="lazy"
                                    id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                    class="order-summary-img img-box">
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text cursor-pointer">{{item.Description}}</div>
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                    class="order-summary-product-text" *ngIf="currentRouterPath =='/Confirmation'">
                                    {{item.Quantity}}</div>
                                <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                    fxLayoutAlign="start center" *ngIf="currentRouterPath !='/Confirmation'">
                                    <div class="input-group" fxLayout="row" fxLayoutAlign="center center" *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                        <div class="input-group-btn">
                                            <button mat-button-icon type="button" fxLayoutAlign="center center">
                                                <mat-icon
                                                    (click)="_sharedMethodService.decreaseProductQuantity(item,item.Quantity,3)">
                                                    remove</mat-icon>
                                            </button>
                                        </div>
                                        <div class="input-control" fxLayoutAlign="center center">
                                            <span>{{item.Quantity}}</span>
                                        </div>
                                        <div class="input-group-btn">
                                            <button mat-button-icon type="button" fxLayoutAlign="center center">
                                                <mat-icon (click)="_sharedMethodService.ContinueRetailOrder(item,1,3);">
                                                    add</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price">${{item.Price |number : '1.2-2'}} USD</div>
                                <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price cursor-pointer"
                                    *ngIf="currentRouterPath !='/Confirmation'">
                                    <mat-icon (click)="removedOrder(i, item.Quantity, item.ItemCode, 'smartOrder')">
                                        close</mat-icon>
                                </div>
                                </ng-container>
                                 
                            </div>
                        </div>
                        <!-- Colorado Retail Delivery Fees -->
                        <div class="w-layout-grid order-summary-grid" *ngIf="_enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 && _enrollSession.data.RetailOrderSummary?.shippingFormData?.State == 'CO'" style="margin-bottom: 10px;">
                            <div fxLayoutAlign="center center">
                                <img src="../../../assets/images/image/retail-delivery-fees.png" loading="lazy" id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt="" class="order-summary-img img-box delivery-fee-image">
                            </div>
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" class="order-summary-product-text">{{ _enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees?.description }}</div>

                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" class="order-summary-product-text" fxLayoutAlign="center start"> {{ _enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees?.quantity }} </div>

                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a" class="order-summary-product-price">${{ _enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees?.price |number : '1.2-2'}} USD</div>

                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a" class="order-summary-product-price cursor-pointer">
                            </div>
                        </div>
                    </div>
                    <div class="order-summary-divider"></div>
                    <div class="order-summary-totals-div-block" *ngIf="_calculateAutoOrder.getIsCallCalculateOrder()">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">Sub Total</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Taxes</div>
                            <div class="order-summary-text">Smart Order Total</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.SubTotal |number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.ShippingTotal |number
                                    : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Tax|number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Total|number :
                                    '1.2-2'}} USD</span>
                            </div>
                        </div>
                    </div>
                    <div class="order-summary-totals-div-block" *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder()">
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text">Sub Total</div>
                            <div class="order-summary-text">Shipping</div>
                            <div class="order-summary-text">Taxes</div>
                            <div class="order-summary-text">Smart Order Total</div>
                        </div>
                        <div class="order-summary-totals-text-div-block">
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.SubTotal |number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.ShippingTotal |number
                                    : '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Tax|number :
                                    '1.2-2'}}</span>
                            </div>
                            <div class="order-summary-text t-a-r ">
                                <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Total|number :
                                    '1.2-2'}} USD</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <div class="order-summary-divider"></div>
    </div>
    <div [ngClass]="{'order-summary-continue-button-div': currentRouterPath != '/Shipping' &&  currentRouterPath != '/Checkout', 'order-summary-continue-button-div-center' : currentRouterPath == '/Shipping' ||  currentRouterPath == '/Checkout' }"
        *ngIf="currentRouterPath != '/Confirmation'">
        <a *ngIf="currentRouterPath != '/Shipping' &&  currentRouterPath != '/Checkout'"
            data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
            class="button continue-button continue-button-mobile w-button"
            (click)="subscribeDialog();onClickChange()">Continue</a>
        <a *ngIf="currentRouterPath == '/Shipping'" data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
            class="button continue-button continue-button-mobile w-button" (click)="onClickChange()">Continue To
            Payment</a>
        <a *ngIf="currentRouterPath == '/Checkout'" data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
            class="button continue-button continue-button-mobile w-button"
            (click)="onClickChange(); onSubmitApplication()">Place Your Order</a>
    </div>
</ng-container>

<!-- Not for Mobile Devices -->


<ng-container *ngIf="!_enrollSession.mobileView">
    <div class="order-summary-div">
        <h1 class="order-summary-h1">ORDER SUMMARY</h1>
        <div class="order-summary-divider"></div>
        <div class="summaryCart">
            <ng-container
                *ngIf="(!_enrollSession.data.RetailOrderSummary || (!_enrollSession.data.RetailOrderSummary.Enrollpack || _enrollSession.data.RetailOrderSummary.Enrollpack.length == 0) && (!_enrollSession.data.RetailOrderSummary.InitialOrders || _enrollSession.data.RetailOrderSummary.InitialOrders.length == 0) && (!_enrollSession.data.RetailOrderSummary.SmartOrders || _enrollSession.data.RetailOrderSummary.SmartOrders.length == 0))">
                <div class="order-summary-totals-div-block">
                    <div class="order-summary-totals-text-div-block">
                        <div class="order-summary-text">Subtotal</div>
                        <div class="order-summary-text">Shipping</div>
                        <div class="order-summary-text">Taxes</div>
                    </div>
                    <div class="order-summary-totals-text-div-block">
                        <div class="order-summary-text">---</div>
                        <div class="order-summary-text">---</div>
                        <div class="order-summary-text">---</div>
                    </div>
                </div>
                <div class="order-summary-divider"></div>
                <div class="order-summary-totals-div-block">
                    <div class="order-summary-totals-text-div-block order-summary-labels">
                        <div class="order-summary-text order-summary-text-total">Your Total:</div>
                    </div>
                    <div class="order-summary-totals-text-div-block">
                        <div class="order-summary-text order-summary-text-total">---</div>
                    </div>
                </div>
            </ng-container>
            <div class="order-summary-product-div-block"
                *ngIf="_enrollSession.data.RetailOrderSummary && ((_enrollSession.data.RetailOrderSummary.Enrollpack && _enrollSession.data.RetailOrderSummary.Enrollpack.length > 0) || (_enrollSession.data.RetailOrderSummary.InitialOrders && _enrollSession.data.RetailOrderSummary.InitialOrders.length > 0) || (_enrollSession.data.RetailOrderSummary.SmartOrders && _enrollSession.data.RetailOrderSummary.SmartOrders.length > 0))">
                <div class="order-label-div"   *ngIf="_enrollSession.data.RetailOrderSummary && (_enrollSession.data.RetailOrderSummary?.Enrollpack && _enrollSession.data.RetailOrderSummary?.Enrollpack.length > 0)">
                    <div class="order-label w-180">
                        <div class="order-label-text" [textContent]="siteType=='fruitsandveggie' ? 'Fruit & Veggie+ Pack' : ( siteType=='collagen' ? 'Collagen Pack' : 'Cante Pack' )"></div>
                    </div>
                </div>
                <div
                    *ngFor="let item of _enrollSession.data.RetailOrderSummary.Enrollpack | sortBy: 'asc':'-Price'; let i = index">
                    <div class="w-layout-grid order-summary-grid">
                        <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                            id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                            class="order-summary-product-text cursor-pointer"></div>
                        <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId"
                            src="{{item.SmallImage? item.SmallImage : 'assets/img/noimage.png'}}" loading="lazy"
                            id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                            [ngClass]="{'gift_img': item.ItemCode=='7800GC'}" class="order-summary-img img-box">
                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                            class="order-summary-product-text cursor-pointer">{{item.Description}}</div>
                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column" fxLayoutAlign="start center" *ngIf="currentRouterPath !='/Confirmation' && item.ItemCode!='7800GC'">
                            <div class="input-group" fxLayout="row" fxLayoutAlign="center center" *ngIf="item.ParentId == item.ItemCode || !item.ParentId" >
                                <div class="input-group-btn">
                                    <button mat-button-icon type="button" fxLayoutAlign="center center" >
                                        <mat-icon (click)="_sharedMethodService.decreaseProductQuantity(item,item.Quantity,1)">remove</mat-icon>
                                    </button>
                                </div>
                                <div class="input-control" fxLayoutAlign="center center">
                                    <span>{{item.Quantity}}</span>
                                </div>
                                <div class="input-group-btn">
                                    <button mat-button-icon type="button" fxLayoutAlign="center center">
                                        <mat-icon (click)="_sharedMethodService.ContinueRetailOrder(item,1,1);">add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>    
                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" *ngIf="item.ItemCode=='7800GC' || (item.ParentId != item.ItemCode && item.ParentId)"
                            class="order-summary-product-text" fxLayoutAlign="center start">{{item.Quantity}}</div>
                        <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                            class="order-summary-product-price"
                            *ngIf="_calculateRetailOrderService.cartSubtotal >= 200">${{item.Price |number : '1.2-2'}}
                            USD</div>
                        <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                            class="order-summary-product-price" *ngIf="_calculateRetailOrderService.cartSubtotal < 200">
                            ${{item.RetailPrice |number : '1.2-2'}} USD</div>
                        <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                            class="order-summary-product-price cursor-pointer"
                            *ngIf="currentRouterPath !='/Confirmation' && i == 0">
                            <span (click)="removedOrder(i , item.Quantity, item.ItemCode, 'transformation')">Edit</span>
                        </div>
                    </div>
                </div>

                
                    
                <!-- Colorado Retail Delivery Fees -->
                <div class="w-layout-grid order-summary-grid" *ngIf="_enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees?.price" style="margin-bottom: 10px;">
                    <div fxLayoutAlign="center center">
                        <img src="../../../assets/images/image/retail-delivery-fees.png" loading="lazy" id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt="" class="order-summary-img img-box delivery-fee-image">
                    </div>
                    <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" class="order-summary-product-text">{{ _enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees.description }}</div>

                    <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" class="order-summary-product-text" fxLayoutAlign="center start"> {{ _enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees.quantity }} </div>

                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a" class="order-summary-product-price">${{ _enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees.price |number : '1.2-2'}} USD</div>

                    <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a" class="order-summary-product-price cursor-pointer">
                    </div>
                </div>

            </div>
            <div class="order-summary-divider"   *ngIf="_enrollSession.data.RetailOrderSummary && (_enrollSession.data.RetailOrderSummary?.Enrollpack && _enrollSession.data.RetailOrderSummary?.Enrollpack.length > 0)"></div>
            <div class="order-summary-product-div-block"
                *ngIf="_enrollSession.data.RetailOrderSummary.InitialOrders && _enrollSession.data.RetailOrderSummary.InitialOrders.length > 0">
                <div class="order-label-div">
                    <div class="order-label">
                        <div class="order-label-text">Today&#x27;s Order</div>
                    </div>
                </div>
                <div *ngFor="let item of _enrollSession.data.RetailOrderSummary.InitialOrders; let i = index">
                    <div class="w-layout-grid order-summary-grid">
                        <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                            id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                            class="order-summary-product-text cursor-pointer"></div>
                        <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId"
                            src="{{item.SmallImage? item.SmallImage : 'assets/img/noimage.png'}}" loading="lazy"
                            id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                            class="order-summary-img img-box">

                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                            class="order-summary-product-text cursor-pointer">{{item.Description}}</div>
                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                            class="order-summary-product-text" fxLayoutAlign="center start"
                            *ngIf="currentRouterPath =='/Confirmation' || item.ItemCode == '7800GC'">{{item.Quantity}}
                        </div>
                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                            fxLayoutAlign="start center"
                            *ngIf="currentRouterPath !='/Confirmation' && item.ItemCode != '7800GC'">
                            <div class="input-group" fxLayout="row" fxLayoutAlign="center center"
                                *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                <div class="input-group-btn">
                                    <button mat-button-icon type="button" fxLayoutAlign="center center">
                                        <mat-icon
                                            (click)="_sharedMethodService.decreaseProductQuantity(item,item.Quantity,2)">
                                            remove</mat-icon>
                                    </button>
                                </div>
                                <div class="input-control" fxLayoutAlign="center center">
                                    <span>{{item.Quantity}}</span>
                                </div>
                                <div class="input-group-btn">
                                    <button mat-button-icon type="button" fxLayoutAlign="center center">
                                        <mat-icon (click)="_sharedMethodService.ContinueRetailOrder(item,1,2);">add
                                        </mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" *ngIf="item.ItemCode=='7800GC' || (item.ParentId != item.ItemCode && item.ParentId)"
                        class="order-summary-product-text" fxLayoutAlign="center start">{{item.Quantity}}</div>
                        <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                            class="order-summary-product-price"
                            *ngIf="_calculateRetailOrderService.cartSubtotal >= 200">${{item.Price |number : '1.2-2'}}
                            USD</div>
                        <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                            class="order-summary-product-price" *ngIf="_calculateRetailOrderService.cartSubtotal < 200">
                            ${{item.RetailPrice |number : '1.2-2'}} USD</div>
                        <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                            class="order-summary-product-price cursor-pointer"
                            *ngIf="currentRouterPath !='/Confirmation' && item.ItemCode != '7800GC'">
                            <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                    class="order-summary-product-price cursor-pointer"
                                    *ngIf="currentRouterPath !='/Confirmation'">
                                    <mat-icon (click)="removedOrder(i , item.Quantity, item.ItemCode, 'todayOrder')">
                                        close</mat-icon>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-summary-divider"></div>
            <div
                *ngIf="_enrollSession.data.RetailOrderSummary.Enrollpack && _enrollSession.data.RetailOrderSummary.Enrollpack.length > 0 || _enrollSession.data.RetailOrderSummary.InitialOrders && _enrollSession.data.RetailOrderSummary.InitialOrders.length > 0  || _enrollSession.data.RetailOrderSummary.AddOnOrders && _enrollSession.data.RetailOrderSummary.AddOnOrders.length > 0">
                <div class="order-summary-totals-div-block" *ngIf="_calculateAutoOrder.getIsCallCalculateOrder()">
                    <div class="order-summary-totals-text-div-block">
                        <div class="order-summary-text">Sub Total</div>
                        <div class="order-summary-text">Shipping</div>
                        <div class="order-summary-text">Tax</div>
                        <div class="order-summary-text">Order Total</div>
                    </div>
                    <div class="order-summary-totals-text-div-block">
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal |number :
                                '1.2-2'}}</span>
                        </div>
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.ShippingTotal |number :
                                '1.2-2'}}</span>
                        </div>
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Tax|number :
                                '1.2-2'}}</span>
                        </div>
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total|number : '1.2-2'}}
                                USD</span>
                        </div>
                    </div>
                </div>
                <div class="order-summary-totals-div-block" *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder()">
                    <div class="order-summary-totals-text-div-block">
                        <div class="order-summary-text">Sub Total</div>
                        <div class="order-summary-text">Shipping</div>
                        <div class="order-summary-text">Taxes</div>
                        <div class="order-summary-text">Your Total</div>
                    </div>
                    <div class="order-summary-totals-text-div-block">
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.SubTotal |number :
                                '1.2-2'}}</span>
                        </div>
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.ShippingTotal |number :
                                '1.2-2'}}</span>
                        </div>
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Tax|number :
                                '1.2-2'}}</span>
                        </div>
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.InitialOrderDetails.Total|number : '1.2-2'}}
                                USD</span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                *ngIf="_enrollSession.data.RetailOrderSummary.SmartOrders && _enrollSession.data.RetailOrderSummary.SmartOrders.length>0">
                <div class="order-summary-divider"></div>
                <div class="order-summary-product-div-block">
                    <div class="order-label-div">
                        <div class="order-label w-215">
                            <div class="order-label-text">Smart Order Subscription</div>
                        </div>
                    </div>
                    <div class="smartOrderDateBoxCart ">
                        <div class="p-r-10">
                            <label>Your Smart Order Date:</label>
                        </div>
                        <div>
                            <div>{{_enrollSession.data.smartOrderDate ? _enrollSession.data.smartOrderDate :
                                '--/--/----'}}</div>
                        </div>
                    </div>
                    <div *ngFor="let item of _enrollSession.data.RetailOrderSummary.SmartOrders; let i = index">
                        <div class="w-layout-grid order-summary-grid">
                            <div *ngIf="item.ParentId != item.ItemCode && item.ParentId"
                            id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                            class="order-summary-product-text cursor-pointer"></div>
                            <img *ngIf="item.ParentId == item.ItemCode || !item.ParentId" src="{{item.SmallImage}}" loading="lazy"
                                id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt=""
                                class="order-summary-img img-box">
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text cursor-pointer">{{item.Description}}</div>
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a"
                                class="order-summary-product-text" fxLayoutAlign="center start"
                                *ngIf="currentRouterPath =='/Confirmation'">{{item.Quantity}}</div>
                            <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" fxLayout="column"
                                fxLayoutAlign="start center" *ngIf="currentRouterPath !='/Confirmation'">
                                <div class="input-group" fxLayout="row" fxLayoutAlign="center center" *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                    <div class="input-group-btn">
                                        <button mat-button-icon type="button" fxLayoutAlign="center center">
                                            <mat-icon
                                                (click)="_sharedMethodService.decreaseProductQuantity(item,item.Quantity,3)">
                                                remove</mat-icon>
                                        </button>
                                    </div>
                                    <div class="input-control" fxLayoutAlign="center center">
                                        <span>{{item.Quantity}}</span>
                                    </div>
                                    <div class="input-group-btn">
                                        <button mat-button-icon type="button" fxLayoutAlign="center center">
                                            <mat-icon (click)="_sharedMethodService.ContinueRetailOrder(item,1,3);">add
                                            </mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price"
                                *ngIf="_calculateRetailOrderService.cartSubtotal >= 200">${{item.Price |number :
                                '1.2-2'}} USD</div>
                            <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price"
                                *ngIf="_calculateRetailOrderService.cartSubtotal < 200">${{item.RetailPrice |number :
                                '1.2-2'}} USD</div>
                            
                            <ng-container *ngIf="item.ParentId == item.ItemCode || !item.ParentId">
                                <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a"
                                class="order-summary-product-price cursor-pointer"
                                *ngIf="currentRouterPath !='/Confirmation'">
                                <mat-icon (click)="removedOrder(i, item.Quantity, item.ItemCode, 'smartOrder')">close
                                </mat-icon>
                            </div>
                            </ng-container>
                              
                        </div>
                    </div>

                    <!-- Colorado Retail Delivery Fees -->
                    <div class="w-layout-grid order-summary-grid" *ngIf="_enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 && _enrollSession.data.RetailOrderSummary?.shippingFormData?.State == 'CO'" style="margin-bottom: 10px;">
                        <div fxLayoutAlign="center center">
                            <img src="../../../assets/images/image/retail-delivery-fees.png" loading="lazy" id="w-node-_66b5be68-7551-fb71-28b7-f7c2b8b1d6d2-3a42862a" alt="" class="order-summary-img img-box delivery-fee-image">
                        </div>
                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" class="order-summary-product-text">{{ _enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees?.description }}</div>

                        <div id="w-node-_166d65c3-417f-51e0-666c-5327e20d4293-3a42862a" class="order-summary-product-text" fxLayoutAlign="center start"> {{ _enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees?.quantity }} </div>

                        <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a" class="order-summary-product-price">${{ _enrollSession.data.RetailOrderSummary.InitialOrderDetails.ColoradoRetailDeliveryFees?.price |number : '1.2-2'}} USD</div>

                        <div id="w-node-bbc5f29b-8e36-2ad3-a343-68e4d29a8e36-3a42862a" class="order-summary-product-price cursor-pointer">
                        </div>
                    </div>


                </div>
                <div class="order-summary-divider"></div>
                <div class="order-summary-totals-div-block" *ngIf="_calculateAutoOrder.getIsCallCalculateOrder()">
                    <div class="order-summary-totals-text-div-block">
                        <div class="order-summary-text">Sub Total</div>
                        <div class="order-summary-text">Shipping</div>
                        <div class="order-summary-text">Taxes</div>
                        <div class="order-summary-text">Smart Order Total</div>
                    </div>
                    <div class="order-summary-totals-text-div-block">
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.SubTotal |number :
                                '1.2-2'}}</span>
                        </div>
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.ShippingTotal |number :
                                '1.2-2'}}</span>
                        </div>
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Tax|number :
                                '1.2-2'}}</span>
                        </div>
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Total|number : '1.2-2'}}
                                USD</span>
                        </div>
                    </div>
                </div>
                <div class="order-summary-totals-div-block" *ngIf="!_calculateAutoOrder.getIsCallCalculateOrder()">
                    <div class="order-summary-totals-text-div-block">
                        <div class="order-summary-text">Sub Total</div>
                        <div class="order-summary-text">Shipping</div>
                        <div class="order-summary-text">Taxes</div>
                        <div class="order-summary-text">Smart Order Total</div>
                    </div>
                    <div class="order-summary-totals-text-div-block">
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.SubTotal |number :
                                '1.2-2'}}</span>
                        </div>
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.ShippingTotal |number :
                                '1.2-2'}}</span>
                        </div>
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Tax|number :
                                '1.2-2'}}</span>
                        </div>
                        <div class="order-summary-text t-a-r ">
                            <span>${{_enrollSession.data.RetailOrderSummary.SmartOrderDetails?.Total|number : '1.2-2'}}
                                USD</span>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'order-summary-continue-button-div': currentRouterPath != '/Shipping' &&  currentRouterPath != '/Checkout', 'order-summary-continue-button-div-center' : currentRouterPath == '/Shipping' ||  currentRouterPath == '/Checkout' }"
                *ngIf="currentRouterPath != '/Confirmation'">
                <a *ngIf="currentRouterPath != '/Shipping' &&  currentRouterPath != '/Checkout'"
                    data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8" class="button continue-button w-button"
                    (click)="subscribeDialog();onClickChange()">Continue</a>
                <a *ngIf="currentRouterPath == '/Shipping'" data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                    class="button continue-button continue-button-shipping w-button" (click)="onClickChange()">Continue To Payment</a>
                <a *ngIf="currentRouterPath == '/Checkout'" data-w-id="27c93564-f197-59a3-480f-c08ea8b984e8"
                    class="button continue-button continue-button-shipping w-button"
                    (click)="onClickChange(); onSubmitApplication()">Place Your Order</a>
            </div>
        </div>
    </div>
</ng-container>