import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnrollSessionService } from 'app/shared/enrollSession.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  steps=[
    {
      stepper_title:["TODAY","SUBSCRIBE","SHIPPING","CHECKOUT"],
      stepper_back:'',
      stepper_images:[],
      routes:[]
    }
  ];
  
  current_stepper_title:any='';
  constructor(
    private _activateRoute: ActivatedRoute,
    public _enrollSession: EnrollSessionService,
  ) {

   }

  ngOnInit(): void {
    console.log('path');
    this.current_stepper_title=this._activateRoute.snapshot.url[0].path;
   console.log(this.current_stepper_title);
   // switch case for images and routerlink
  switch (this.current_stepper_title) {
    case 'TodaysOrder':
      this.steps[0].stepper_back=""
      this.steps[0].stepper_images=["assets/images/stepper-logo/Step-1-Green.svg",
                            "assets/images/stepper-logo/Step-2-Gray.svg",
                            "assets/images/stepper-logo/Step-3-Gray.svg",
                            "assets/images/stepper-logo/Step-4-Gray.svg"
                              ]
      this.steps[0].routes=["../TodaysOrder","","",""]                       
      console.log(this.steps[0].stepper_images);
      break;

    case 'Subscribe':
        console.log(this.current_stepper_title,this.steps[0].stepper_images);
        this.steps[0].stepper_back="../TodaysOrder"
        this.steps[0].stepper_images=["assets/images/stepper-logo/Circle-Check.svg",
                              "assets/images/stepper-logo/Step-2-Green.svg",
                              "assets/images/stepper-logo/Step-3-Gray.svg",
                              "assets/images/stepper-logo/Step-4-Gray.svg",
                                ]
        this.steps[0].routes=["../TodaysOrder","../Subscribe","",""]                       
       break;


    case 'Shipping':
      console.log(this.current_stepper_title,this.steps[0].stepper_images);
      this.steps[0].stepper_back="../Subscribe"
      this.steps[0].stepper_images=["assets/images/stepper-logo/Circle-Check.svg",
                            "assets/images/stepper-logo/Circle-Check.svg",
                            "assets/images/stepper-logo/Step-3-Green.svg",
                            "assets/images/stepper-logo/Step-4-Gray.svg"
                              ]
      this.steps[0].routes=["../TodaysOrder","../Subscribe","../Shipping",""]                       
      console.log(this.steps[0].stepper_images);
      break;


    case 'Checkout':
      console.log(this.current_stepper_title,this.steps[0].stepper_images);
      this.steps[0].stepper_back="../Shipping"
      this.steps[0].stepper_images=["assets/images/stepper-logo/Circle-Check.svg",
                            "assets/images/stepper-logo/Circle-Check.svg",
                            "assets/images/stepper-logo/Circle-Check.svg",
                            "assets/images/stepper-logo/Step-4-Green.svg"
                              ]
                              this.steps[0].routes=["../TodaysOrder","../Subscribe","../Shipping","../Checkout"]                       
                      
        console.log(this.steps[0].stepper_images);
        break;

        }
  //switch close

  }
 
  hide(i)
  {
    if(this.steps[0].stepper_title.length-1==i)
    {
      return false;
    }
    else
    {
      return true;
    }
  }



}
  

