import { NgModule } from '@angular/core';
import {ShippingModule} from './Shipping/Shipping.module';
import { ConfirmationModule } from './Confirmation/Confirmation.module';
import { SubscribeModule } from './Subscriptions/Subscribe.module';
import { CheckoutModule } from './checkout/checkout.module';
import { SummaryCartModule } from './summary-cart/summary-cart.module';
import { PipesModule } from './pipe/pipe.module';
import { StepperModule } from './stepper/stepper.module';
import { TodaysOrderModule } from './Todays-order/Todays-order.module';
// import { SubscribeDialogComponent } from './subscribe-dialog/subscribe-dialog.component';
@NgModule({
    imports: [
        TodaysOrderModule,
        SubscribeModule,
        ShippingModule,
        ConfirmationModule,
        CheckoutModule,
        SummaryCartModule,
        PipesModule,
        StepperModule

    ],
    exports: [
        TodaysOrderModule,
        SubscribeModule,
        ShippingModule,
        ConfirmationModule,
        CheckoutModule,
        PipesModule,
    ],
    declarations: [
    // SubscribeDialogComponent

  ]
})
export class PagesModule
{

}
