import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { GetDiscountedItemsService } from './getDiscountedItems.service';
import { RestService } from './rest.service';
import { ToastrService } from 'ngx-toastr';
import { EnrollSessionService } from './enrollSession.service';


@Injectable({
    providedIn: 'root'
})
export class CalculateRetailOrderService {
    itemDetail: any = [];
    ArrayList: any = [];
    ParentItemCode: any;
    IsProfessional: any;
    IsNonprofit: any;
    getProfessionalItems: any;
    discitem: any;
    disCountItem: any;
    priceType: any;
    cartSubtotal: any;
    constructor(
        private _toastr: ToastrService,
        private _restService: RestService,
        private _getDiscountedItemsService: GetDiscountedItemsService,
        private _enrollSession: EnrollSessionService
    ) {
        this._enrollSession.data = JSON.parse(sessionStorage.getItem('EnrollSession')) || {};
    }
    calculate(items: any, ShipMethodId: any, callback: any, type: any): any {
        this.itemDetail = [];
        this.ArrayList = [];
        this.ParentItemCode = '';
        this.IsProfessional = false;
        this.IsNonprofit = '';
        this.priceType = 2;
        this.cartSubtotal = 0;
        this.getProfessionalItems = false;
        this.discitem = undefined;
        this._enrollSession.data.RetailOrderSummary.InitialOrders = this._enrollSession.data.RetailOrderSummary.InitialOrders || [];
        this._enrollSession.data.RetailOrderSummary.AddOnOrders = this._enrollSession.data.RetailOrderSummary.AddOnOrders || [];
        var ConcatArray = this._enrollSession.data.RetailOrderSummary.InitialOrders.concat(this._enrollSession.data.RetailOrderSummary.Enrollpack);
        if (type === 4) {
            this.ArrayList = items;
        }
        else {
            this.ArrayList = ConcatArray;
        }

        _.each(this.ArrayList, (obj) => {
            if (obj.ParentId && parseInt(obj.ParentId, 10) !== parseInt(obj.ItemCode, 10)) {
                this.ParentItemCode = obj.ParentId.toString();

            }
            else {
                this.ParentItemCode = '';
            }
            this.itemDetail.push({ ItemCode: obj.ItemCode, Quantity: obj.Quantity, ParentItemCode: this.ParentItemCode });
            if (obj.ItemCode !== "7800GC") {
                obj.RetailPrice = obj.RetailPrice ? obj.RetailPrice : 0;
                this.cartSubtotal += parseFloat(obj.RetailPrice) * parseInt(obj.Quantity, 10);
            }
        });
        
        if (type === 0 && this._enrollSession.data.RetailOrderSummary.InitialOrders.length > 0) {
            if(this._enrollSession.data.RetailOrderSummary.InitialOrders.length == 1 && this._enrollSession.data.RetailOrderSummary.InitialOrders[0].ItemCode == "7800GC")
            {
                this._enrollSession.data.RetailOrderSummary.InitialOrders = [];
            }
            this.disCountItem = this._enrollSession.data.RetailOrderSummary.InitialOrders.find((item, index) => {
                return item.ItemCode == "7800GC";
            });

            if (this.cartSubtotal < 200 && !this.disCountItem) {
                this.discitem = this._getDiscountedItemsService.getDiscount(this.cartSubtotal);
                if (this.discitem !== undefined && this.discitem.itemCode !== '') {
                    this.itemDetail.push({ ItemCode: this.discitem.itemCode, Quantity: this.discitem.qty, ParentItemCode: '' });
                }
            }
            else if ((this.cartSubtotal >= 200||this.cartSubtotal < 75) && this.disCountItem) {
                const index = this._enrollSession.data.RetailOrderSummary.InitialOrders.indexOf(this.disCountItem);
                this._enrollSession.data.RetailOrderSummary.InitialOrders.splice(index, 1)
                this.itemDetail = [];
                var newArrayList = this._enrollSession.data.RetailOrderSummary.InitialOrders.concat(this._enrollSession.data.RetailOrderSummary.InitialOrders);
                _.each(newArrayList, (obj) => {
                    if (obj.ParentId && parseInt(obj.ParentId, 10) !== parseInt(obj.ItemCode, 10)) {
                        this.ParentItemCode = obj.ParentId.toString();
                    }
                    else {
                        this.ParentItemCode = '';
                    }
                    this.itemDetail.push({ ItemCode: obj.ItemCode, Quantity: obj.Quantity, ParentItemCode: this.ParentItemCode });
                });
            }
            this._enrollSession.data.OrderProductDetalis = this.itemDetail;
        }
        else {
            this._enrollSession.data.SmartOrderProductDetalis = this.itemDetail;
            this.IsProfessional = false;
            this.IsNonprofit = false;
        }
        console.log("carttotal", this.cartSubtotal);
        if (this.cartSubtotal >= 200) {
            this.priceType = 10;
        }
        this._enrollSession.data.priceType = this.priceType;
        
        try {
            const request = {
                ShipMethodId: ShipMethodId ? ShipMethodId : (this._enrollSession.data.RetailEnroll.ShippingMethod ? this._enrollSession.data.RetailEnroll.ShippingMethod : 0),
                ShippingCity: this._enrollSession.data.RetailEnroll.City ? this._enrollSession.data.RetailEnroll.City : '',
                State: this._enrollSession.data.RetailEnroll.State ? this._enrollSession.data.RetailEnroll.State : '',
                Zip: this._enrollSession.data.RetailEnroll.ZipCode ? this._enrollSession.data.RetailEnroll.ZipCode : '',
                Country: this._enrollSession.data.RetailEnroll.CountryCode ? this._enrollSession.data.RetailEnroll.CountryCode : '',
                Items: this.itemDetail,
                IsProfessional: this.IsProfessional ? true : false,
                IsNonprofit: this.IsNonprofit ? true : false,
                PriceType: this.priceType,
                // isDynamicKitChildItem:this._enrollSession.data.isDynamicKitChildItem 
                // IsValidCoupon: this._enrollSession.data.IsValidGiftCard || false
                OrderType: type === 4 ? 4 : 0
            };
            this._enrollSession.data.RetailEnroll.IsNonprofit = this.IsNonprofit;
            this._restService.calculateRetailOrder(request).subscribe((result: any) => {
                if (result) {

                    if (this.itemDetail.length > 0 && result.Warnings.length > 0) {
                        this._toastr.error(result.Warnings[0], 'Error');
                    }


                    this._enrollSession.data.calculateRetailOrderData = result;
                    this._enrollSession.data.calculateRetailOrder = result.CustomDetails;

                    if (type !== 4) {
                        this._enrollSession.data.ShippingMethods = [];
                        this._enrollSession.data.ShippingMethods = result.CustomShipMethods;
                        // this._enrollSession.data.ShippingMethods = result.CustomShipMethods ? result.CustomShipMethods.slice(0, 1) : result.CustomShipMethods;
                        this._enrollSession.data.RetailEnroll.ShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod ||
                            result.CustomShipMethods && result.CustomShipMethods[0].ShipMethodID;
                        const discountedItem = _.filter(result.CustomDetails, (item) => {
                            return item.ItemCode === '7800GC';
                        });


                        this._enrollSession.data.RetailOrderSummary.InitialOrders =
                            _.without(this._enrollSession.data.RetailOrderSummary.InitialOrders, _.findWhere(this._enrollSession.data.RetailOrderSummary.Enrollpack, { ItemCode: "7800GC" }));

                        if (discountedItem.length > 0 && !this.disCountItem && this.cartSubtotal >= 75) {
                            this._enrollSession.data.RetailOrderSummary.InitialOrders.push({
                                ItemCode: discountedItem[0].ItemCode,
                                Description: discountedItem[0].Description,
                                Quantity: discountedItem[0].Quantity,
                                Price: discountedItem[0].PriceTotal,
                                RetailPrice: discountedItem[0].PriceTotal,
                                ShippingPrice: discountedItem[0].ShippingPrice,
                                TinyImage: discountedItem[0].TinyPicture,
                                SmallImage: "assets/images/enhance-image/gift-card-img.png",//discountedItem[0].SmallPicture,
                                BV: discountedItem[0].BusinesVolume,
                                CV: discountedItem[0].CommissionableVolume,
                                TaxablePrice: discountedItem[0].Taxable,
                                LongDetail: discountedItem[0].LongDetail,
                                LongDetail2: discountedItem[0].LongDetail2,
                                LongDetail3: discountedItem[0].LongDetail3,
                                LongDetail4: discountedItem[0].LongDetail4,
                                IsUSOnly: false
                            });
                        }
                    }

                    if (this._enrollSession.data.ShippingMethods) {
                        this._enrollSession.data.ShippingMethods.forEach((method) => {
                            if (parseInt(method.ShipMethodID, 10) === parseInt(this._enrollSession.data.RetailEnroll.ShippingMethod, 10)) {
                                this._enrollSession.data.Order_descriptionField = method.Description;
                                this._enrollSession.data.Shipping_descriptionField = method.Description;
                                this._enrollSession.data.Order_shipMethodIDField = this._enrollSession.data.RetailEnroll.ShippingMethod;
                            }
                        });
                    }


                    /**** calculateRetailOrder ****/
                    this._enrollSession.data.orderdItemsData = [];
                    this._enrollSession.data.InitOrder.forEach((item: any) => {
                        this._enrollSession.data.calculateRetailOrder.some((calcItem: any) => {
                            if (parseInt(calcItem.itemCodeField, 10) === item.ItemCode) {
                                this._enrollSession.data.orderdItemsData.push({
                                    ItemCode: calcItem.ItemCode,
                                    Quantity: calcItem.Quantity,
                                    // isDynamicKitChildItem:this._enrollSession.data.isDynamicKitChildItem,
                                    Price: calcItem.PriceEach,
                                    DescriptionField: calcItem.Description,
                                    PriceTotalField: calcItem.PriceTotal
                                });
                            }
                        });
                    });

                    /******************/

                    if (this.itemDetail.length > 0) {
                        if (result.Result.statusField === 0) {
                            if (callback) {
                                callback(result);
                            }
                        }
                        else {
                            this._toastr.error(result.Message);
                        }
                    }
                }
            },
                error => {
                    console.log(error);
                    this._toastr.error('Unable to calculate order.', 'Error');
                });
        } catch (e) {
            this._toastr.error('Unable to calculate order.', 'Error');
        }
    }

    TotalItemLength(): any {
        if (this._enrollSession.data.customertype === 22) {
            if (this._enrollSession.data.checkoutwithlimited) {
                return this._enrollSession.data.RetailOrderSummary &&
                    (this._enrollSession.data.RetailOrderSummary.InitialOrders && this._enrollSession.data.RetailOrderSummary.InitialOrders.length) +
                    (this._enrollSession.data.RetailOrderSummary.AddOnOrders && this._enrollSession.data.RetailOrderSummary.AddOnOrders.length) +
                    (this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length) || 0;
            }
            else {
                return this._enrollSession.data.RetailOrderSummary &&
                    (this._enrollSession.data.RetailOrderSummary.Enrollpack && this._enrollSession.data.RetailOrderSummary.Enrollpack.length) +
                    (this._enrollSession.data.RetailOrderSummary.AddOnOrders && this._enrollSession.data.RetailOrderSummary.AddOnOrders.length) +
                    (this._enrollSession.data.RetailOrderSummary.InitialOrders && this._enrollSession.data.RetailOrderSummary.InitialOrders.length) +
                    (this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length) || 0;
            }
        }
        else {
            return this._enrollSession.data.RetailOrderSummary &&
                (this._enrollSession.data.RetailOrderSummary.Enrollpack && this._enrollSession.data.RetailOrderSummary.Enrollpack.length) +
                (this._enrollSession.data.RetailOrderSummary.InitialOrders && this._enrollSession.data.RetailOrderSummary.InitialOrders.length) +
                (this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length) || 0;
        }
    }
}
