import { Component, EventEmitter, HostListener, Input, OnInit, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CalculateAutoOrderService } from 'app/shared/calculateAutoOrder.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import * as _ from 'underscore';
import { SubscribeDialogComponent } from 'app/shared/subscribe-dialog/subscribe-dialog.component';
import { GetDiscountedItemsService } from 'app/shared/getDiscountedItems.service';
import { CalculateRetailInitialOrderService } from 'app/shared/calculateRetailInitialOrder.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { CalculateRetailSmartOrderService } from 'app/shared/CalculateRetailSmartOrder.service';
import { takeUntil } from 'rxjs/operators';
import { CalculateRetailOrderService } from 'app/shared/calculateRetailOrder.service';

@Component({
    selector: 'summary-cart',
    templateUrl: './summary-cart.component.html',
    styleUrls: ['./summary-cart.component.scss']
})
export class SummaryCartComponent implements OnInit, OnDestroy {
    @Input() routPath: any;
    @Input() customerInfoForm: any;
    @Input() shippingForm: any;
    @Input() shippingMethodForm: any;
    @Input() referredForm: any;
    @Input() billingAddressForm: any;
    @Input() paymentTypeForm: any;
    @Input() subscribeDialogShow: any;
    @Output() submitApplication = new EventEmitter<any>();
    dialogRef: any;
    currentRouterPath: any;
    finalShippingMethod: any;
    siteType: any;
    private _unsubscribeAll: Subject<any>;
    constructor(
        private _toastr: ToastrService,
        private _matDialog: MatDialog,
        private router: Router,
        public _enrollSession: EnrollSessionService,
        public _calculateAutoOrder: CalculateAutoOrderService,
        private _calculateRetailInitialOrder: CalculateRetailInitialOrderService,
        private _calculateRetailSmartOrder: CalculateRetailSmartOrderService,
        public _sharedMethodService: SharedMethodService,
        public _calculateRetailOrderService: CalculateRetailOrderService,
        private ref: ChangeDetectorRef,
    ) {
        if (this._enrollSession.data.RetailOrderSummary == null) {
            this.router.navigate(['/TodaysOrder']);
        }
        this._unsubscribeAll = new Subject();
        this.finalShippingMethod = this.finalShippingMethod || 0;
        this.siteType = sessionStorage.getItem("siteType");
    }

    ngOnInit(): void {     
        this._calculateRetailOrderService.cartSubtotal = 0;
        var cartSubTotal = 0;
        _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, function (item) {
            if (item.ItemCode !== "7800GC") {
                cartSubTotal += parseFloat(item.RetailPrice) * parseInt(item.Quantity, 10);
            }
        });
        _.each(this._enrollSession.data.RetailOrderSummary.Enrollpack, function (item) {
            if (item.ItemCode !== "7800GC") {
                cartSubTotal += parseFloat(item.RetailPrice) * parseInt(item.Quantity, 10);
            }
        });
        this._calculateRetailOrderService.cartSubtotal = cartSubTotal;
        this.currentRouterPath = this.router.url.split('?')[0];
        this._sharedMethodService.checkSubTotal.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            if (res) {
                if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
                    this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
                } else {
                    this.finalShippingMethod = '';
                }
                this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
            }
        })
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    subscribeDialog() {
        if (this.currentRouterPath == "/Subscribe" && this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0) {
            this.router.navigate(['/', this.routPath]);
        }
        else if (this.currentRouterPath == "/Subscribe") {
            this.dialogRef = this._matDialog.open(SubscribeDialogComponent, {
                width: '500px',
                maxWidth: '500px',
                height: '356px',
                panelClass: '',
                disableClose: true
            });
            this.dialogRef.afterClosed()
                .subscribe(response => {
                    if (!response) {
                        return;
                    } else {
                        if (response == 'false') {
                            this.router.navigate(['/', this.routPath])
                        }
                        if (response == 'true') {
                            return
                        }
                    }

                });

        }
    }
    onSubmitApplication() {
        this.submitApplication.emit();
    }
    onClickChange() {
        if (this.customerInfoForm || this.shippingForm || this.shippingMethodForm) {
            if (this.shippingForm && this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 == undefined) {
                this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 = "";
            }
            if (this.customerInfoForm.invalid || this.shippingForm.invalid || this.shippingMethodForm.invalid) {
                if (this.customerInfoForm.invalid) {
                    this.validateAllFormFields(this.customerInfoForm);
                }
                if (this.shippingForm.invalid) {
                    this.validateAllFormFields(this.shippingForm);
                }
                if (this.shippingMethodForm.invalid) {
                    this.validateAllFormFields(this.shippingMethodForm);
                }
                this._toastr.error('Please fill all required fields', 'error');
                return;
            }
        }

        if (this.referredForm || this.billingAddressForm || this.paymentTypeForm) {
            if (this.billingAddressForm && this._enrollSession.data.RetailOrderSummary.billingAddressData.address2 == undefined) {
                this._enrollSession.data.RetailOrderSummary.billingAddressData.address2 = "";
            }
            if (this.referredForm.invalid || this.billingAddressForm.invalid || this.paymentTypeForm.invalid) {
                if (this.referredForm.invalid) {
                    this.validateAllFormFields(this.referredForm);
                    this._toastr.error('Please fill the referral code', 'error');
                }
                if (this.billingAddressForm.invalid) {
                    this.validateAllFormFields(this.billingAddressForm);
                    this._toastr.error('Please fill all required fields', 'error');
                }
                if (this.paymentTypeForm.invalid) {
                    this.validateAllFormFields(this.paymentTypeForm);
                    this._toastr.error('Please fill all required fields', 'error');
                }
               
                return;
            }
        }
        if (this.currentRouterPath == "/Shipping" && this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.SmartOrders && this._enrollSession.data.RetailOrderSummary.SmartOrders.length > 0 && (this._enrollSession.data.smartOrderDate == undefined || this._enrollSession.data.smartOrderDate == null || this._enrollSession.data.smartOrderDate == '')) {
            this._toastr.error('Please select Smart Order Date', 'error');
            return;
        }
        if (this.routPath != "" && this.routPath != null && this.routPath != "Shipping") {
            this.router.navigate(['/', this.routPath]);
        }
    }
    removedOrder(index, Quantity, parentId, orderType) {
        if (orderType == 'todayOrder') {
            this.removeInitOrder({ index: index, Quantity: Quantity, parentId: parentId });
        }
        if (orderType == 'smartOrder') {
            this.removeSmartOrder({ index: index, Quantity: Quantity, parentId: parentId });
        }
        if (orderType == 'transformation') {
            this.EditTransformPack();
        }
        if((this._enrollSession.data.RetailOrderSummary == undefined) ||  this._enrollSession.data.RetailOrderSummary.InitialOrders==undefined || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.InitialOrders?.length == 0)){
            this._toastr.warning("Please select an item", 'Warning');
        this.router.navigate(['/TodaysOrder']);
       }
    }


    // ****removeSmartOrder****/
    removeSmartOrder(Value: any): any {
        this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
        for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.SmartOrders.length; arrIndex++) {
            if ((!this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ParentId ||
                this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ParentId === Value.parentId) &&
                this._enrollSession.data.RetailOrderSummary.SmartOrders[arrIndex].ItemCode === Value.parentId) {
                this._enrollSession.data.RetailOrderSummary.SmartOrders.splice(arrIndex, 1);
            }
        }

        this._enrollSession.data.RetailOrderSummary.SmartOrders = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
            return item.ParentId !== Value.parentId;
        });
        const filterResult = _.filter(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
            return item.ParentId !== undefined;
        });
        this._enrollSession.data.SmartOrderSubItems = _.groupBy(filterResult, 'ParentId');

        this._enrollSession.data.SmartOrdersSubTotal = 0;
        this._enrollSession.data.SmartOrderBVTotal = 0;
        _.each(this._enrollSession.data.RetailOrderSummary.SmartOrders, (item) => {
            this._enrollSession.data.SmartOrdersSubTotal = this._enrollSession.data.SmartOrdersSubTotal + (item.Quantity * item.Price);
            this._enrollSession.data.SmartOrderBVTotal = this._enrollSession.data.SmartOrderBVTotal + (item.Quantity * item.BV);
        });
        if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
            this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
        } else {
            this.finalShippingMethod = '';
        }
        this._calculateRetailSmartOrder.calculate(this.finalShippingMethod);
    }

    // ****removeInitOrder****/
    removeInitOrder(Value: any): any {
        this._enrollSession.data.CartQuantity = this._enrollSession.data.CartQuantity - Value.Quantity;
        for (let arrIndex = 0; arrIndex < this._enrollSession.data.RetailOrderSummary.InitialOrders.length; arrIndex++) {
            if ((!this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ParentId ||
                this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ParentId === Value.parentId) &&
                this._enrollSession.data.RetailOrderSummary.InitialOrders[arrIndex].ItemCode === Value.parentId) {
                this._enrollSession.data.RetailOrderSummary.InitialOrders.splice(arrIndex, 1);
            }
        }
        this._enrollSession.data.InitialOrdersSubTotal = 0;
        this._enrollSession.data.InitialOrdersQVTotal = 0;
        _.each(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            this._enrollSession.data.InitialOrdersSubTotal = this._enrollSession.data.InitialOrdersSubTotal + (item.Quantity * item.Price);
            this._enrollSession.data.InitialOrdersQVTotal = this._enrollSession.data.InitialOrdersQVTotal + (item.Quantity * item.BV);
        });
        this._enrollSession.data.RetailOrderSummary.InitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            return item.ParentId !== Value.parentId;
        });
        this._enrollSession.data.TotalItemLength = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            return !item.ParentId || (parseInt(item.ParentId, 10) === parseInt(item.ItemCode, 10));
        });
        const filterInitialOrders = _.filter(this._enrollSession.data.RetailOrderSummary.InitialOrders, (item) => {
            return item.ParentId && item.ParentId !== undefined;
        });

        this._enrollSession.data.InitialOrdersSubItems = '';
        this._enrollSession.data.InitialOrdersSubItems = _.groupBy(filterInitialOrders, 'ParentId');
        if (this._enrollSession.data.RetailEnroll.ShippingMethod) {
            this.finalShippingMethod = this._enrollSession.data.RetailEnroll.ShippingMethod;
        } else {
            this.finalShippingMethod = '';
        }
        this._calculateRetailInitialOrder.calculate(this.finalShippingMethod);
    }
    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control: any = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else {
                this.validateAllFormFields(control);
            }
        });
    }
    EditTransformPack() {
        this._enrollSession.editTransformationPack = "true";
        this.router.navigate(['/TodaysOrder']);
    }
    ngDoCheck() {
        this.ref.markForCheck();
    }
}
