<div class="page-wrapper">
    <div class="ult-checkout-section wf-section">
      <summary-cart *ngIf="_enrollSession.mobileView"></summary-cart>
      <div class="checkout-div">
        <div class="order-confirmation-container"><img src="assets/images/image/LOGO-1.svg" loading="lazy" alt="" class="confirmation-logo">
          <div class="order-number-main-div">
            <div class="order-number-icon-div"><img src="assets/images/image/Order-Check.svg" loading="lazy" alt=""></div>
            <div class="order-number-text-div">
              <div class="order-number-text">Order # {{_enrollSession.submitApplicationData.OrderID ? _enrollSession.submitApplicationData.OrderID : ''}}</div>
              <div class="order-number-text">Customer # {{_enrollSession.submitApplicationData.CustomerID ? _enrollSession.submitApplicationData.CustomerID : ''}}</div>
            </div>
          </div>
          <h1 class="order-confirmation-h1">Congratulations On Your Transformation!</h1>
          <div class="map-div">
            <div>
              <google-map
                  height="500px"
                  width="100%"
                  [zoom]="zoom"
                  [center]="center"
                  [options]="options">
                <map-marker
                    *ngFor="let marker of markers"
                    [position]="marker.position"
                    [options]="marker.options">
                </map-marker>    
              <div class="mapButton">
                <div>
                  <button class="borderRadius" mat-button (click)="zoomIn()"><mat-icon>add</mat-icon></button>
                </div>
                <div>
                  <button class="borderRadius" mat-button (click)="zoomOut()"><mat-icon>remove</mat-icon></button>
                </div>
              </div>
              </google-map>
            <div class="order-confirmed-text-div">
              <div class="order-confirmed-h1">Your order is confirmed!</div>
              <div class="order-confirmed-p">A confirmation email has been sent to {{_enrollSession.data.RetailOrderSummary.customerInfoData.Email}}</div>
            </div>
          </div>
          <div class="confirmation-customer-info-main-div">
            <div class="confirmation-customer-info-h1">Customer Information</div>
            <div class="w-layout-grid customer-info-grid">
              <div class="confirmation-customer-info-text-div">
                <div class="confirmation-customer-info-h2">Shipping address</div>
                <div class="confirmation-customer-info-p"> {{_enrollSession.data.RetailOrderSummary.shippingFormData.address1}}  {{_enrollSession.data.RetailOrderSummary.shippingFormData.address2}},<br>
                  {{_enrollSession.data.RetailOrderSummary.shippingFormData.City}}, {{_enrollSession.data.RetailOrderSummary.shippingFormData.State}}<br>
                  {{_enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode}}, {{_enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode}}</div>
              </div>
              <div class="confirmation-customer-info-text-div">
                <div class="confirmation-customer-info-h2">Billing address</div>
                <div class="confirmation-customer-info-p">{{_enrollSession.data.RetailOrderSummary.billingAddressData.address1}}  {{_enrollSession.data.RetailOrderSummary.billingAddressData.address2}},<br>
                  {{_enrollSession.data.RetailOrderSummary.billingAddressData.City}}, {{_enrollSession.data.RetailOrderSummary.billingAddressData.State}}<br>
                  {{_enrollSession.data.RetailOrderSummary.billingAddressData.CountryCode}}, {{_enrollSession.data.RetailOrderSummary.billingAddressData.ZipCode}}</div>
              </div>
              <div class="confirmation-customer-info-text-div">
                <div class="confirmation-customer-info-h2">Shipping method</div>
                <div class="confirmation-customer-info-p">{{_enrollSession.data.RetailEnroll.ShippingDescription}}</div>
              </div>
              <div class="confirmation-customer-info-text-div">
                <div class="confirmation-customer-info-h2">Payment method</div>
                <div class="confirmation-customer-info-p">Visa ending with {{cardNum}}<br></div>
              </div>
            </div>
          </div>
          <a href="https://blog.puriumcorp.com/2019/12/16/lifestyle-transformation-success-stories-2/" target="_blank" class="successs-stories-button w-button">Click here to see success others have had!</a>
        </div>
      </div>
    </div>
    <summary-cart *ngIf="!_enrollSession.mobileView"></summary-cart>
  </div>
</div>  