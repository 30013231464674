<div id="Order" class="page-layout enhance">
  <div class="page-wrapper">
    <div class="ult-checkout-section wf-section">
      <app-stepper *ngIf="_enrollSession.mobileView"></app-stepper>
      <summary-cart [routPath]="routPath" *ngIf="_enrollSession.mobileView"></summary-cart>
      <div class="checkout-div">
      <app-stepper *ngIf="!_enrollSession.mobileView"></app-stepper>
        <!-- <h1 class="checkout-h1">Enhance Your Pack<span class="f-s-20">(Optional)</span></h1>
        <div class="w-layout-grid enhance-grid cen" *ngIf="_enrollSession.mobileView">
          <div *ngFor="let item of EnhanceTranformation" class="m-b-30">
            <div class="itemBorder" (click)="productInfo(item)">
              <img src="{{item.SmallPicture}}" loading="lazy" alt=""
              class="cursor-pointer">
            </div>
            <div class="tooltip">
              <div class="checkout-h2 enhance-grid-h2 text-truncate">{{item.Description}}
                <span class="tooltiptext">{{item.Description}}</span>
              </div>
            </div>
            <div class="enhance-grid-price mh-22">LOYAL CUSTOMER PRICE : ${{item.Price |number : '1.2-2'}}</div>
            <div class="enhance-grid-price mh-22 r-b">RETAIL PRICE : ${{item.Other1Price |number : '1.2-2'}}</div>
            <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{item.ShortDetail3}}</div>
            <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59" class="button enhance-grid-button w-button"
              (click)="_sharedMethodService.ContinueRetailOrder(item,1,2);">Add To Cart</a>
          </div>
        </div> -->
        <!-- <div class="w-layout-grid enhance-grid" *ngIf="!_enrollSession.mobileView">
          <div *ngFor="let item of EnhanceTranformation" class="m-b-30">
            <div class="itemBorder" (click)="productInfo(item)" fxLayoutAlign="center center">
              <img src="{{item.SmallPicture}}" loading="lazy" alt=""
              class="cursor-pointer">
            </div>
            <div class="tooltip">
              <div class="checkout-h2 enhance-grid-h2 text-truncate">{{item.Description}}
                <span class="tooltiptext">{{item.Description}}</span>
              </div>
            </div>
            <div class="enhance-grid-price mh-22">LOYAL CUSTOMER PRICE : ${{item.Price |number : '1.2-2'}}</div>
            <div class="enhance-grid-price mh-22 r-b">RETAIL PRICE : ${{item.Other1Price |number : '1.2-2'}}</div>
            <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{item.ShortDetail3}}</div>
            <a class="button enhance-grid-button w-button"
              (click)="_sharedMethodService.ContinueRetailOrder(item,1,2);">Add To Cart</a>
          </div>
        </div> -->
        <mat-accordion class="example-headers-align" *ngIf="productList?.length > 0" >
          <mat-expansion-panel class="m-t-80" [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="allProductHeader">
              <mat-panel-title>
                <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="start" fxLayoutAlign.gt-xs="space-between none" fxFlex>
                    <div fxLayoutAlign="start center" fxFlex="100" fxFlex.gt-md="49">
                      <h1 class="order-summary-h1 all-products-accordion">All Purium Products</h1>
                    </div>
                    <div *ngIf="panelOpenState" fxLayoutAlign="end center" fxLayoutAlign.lt-lg="center start" fxFlex="100" fxFlex.gt-md="49" class="searchBox" (keydown)="$event.stopImmediatePropagation();" (click)="$event.stopPropagation();">
                      <input class="searchInput" type="text"  (input)="onSearchChangeEnhance($event)" placeholder="Search Your Product" fxFlex>
                      <mat-icon matSuffix>search</mat-icon>
                    </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- <div fxLayout="row" fxLayoutAlign="end center">
            <div  fxLayoutAlign.lt-lg="center start" fxLayoutAlign="space-between center" fxFlex="40" class="searchBox" (keydown)="$event.stopImmediatePropagation();" (click)="$event.stopPropagation();">
                <input class="searchInput" type="text"  (input)="onSearchChangeEnhance($event)" placeholder="Search Your Product" fxFlex>
                <mat-icon matSuffix>search</mat-icon>
            </div>
            </div> -->
            <div class="all-products-accordion-item">
              <div *ngIf="!_enrollSession.mediumScreen">
                <div *ngFor="let product of productList | sortBy: 'asc':'Description' | search: 'Description':onSearchEnhance" >
                  <div class="w-layout-grid all-products-grid grid">
                    <div class="free-oder" (click)="productInfo(product)">
                      <img src="{{product.SmallPicture}}" loading="lazy"
                        id="w-node-_421021f4-112c-5413-f715-320b54c43fe5-db337a59" alt=""
                        class="all-products-placeholder-img cursor-pointer">
                    </div>
                    <div class="tooltip align-center">
                      <div id="w-node-_97ec39c2-7213-1023-164a-ea6b31ad721f-db337a59" class="all-products-product-name text-truncate-all-prod">
                        {{product.Description}}<span class="tooltiptext">{{product.Description}}</span>
                      </div>
                    </div>
                    <div id="w-node-cd5718ae-6526-5955-b798-aee0dccf7998-db337a59" class="all-products-product-price prod-price" >
                      <div >LOYAL CUSTOMER PRICE : ${{product.Price |number : '1.2-2'}} USD</div>
                      <div class="r-b">RETAIL PRICE : ${{product.Other1Price |number : '1.2-2'}} USD</div>
                    </div>
                    <a id="w-node-_263a773b-b3b9-3901-44ef-9e82a85a78f1-db337a59"
                      class="button enhance-grid-button w-button"
                      (click)="addToCart(product,1,2);">Add To Cart</a>
                  </div>
                </div>
              </div>
              <div class="w-layout-grid enhance-grid enhance-grid-mobile" *ngIf="_enrollSession.mediumScreen">
                <div *ngFor="let product of productList | sortBy: 'asc':'Description' | search: 'Description':onSearchEnhance">
                  <div class="free-oder" (click)="productInfo(product)">
                    <img src="{{product.SmallPicture}}" loading="lazy"
                      class="checkout-product-img checkout-product-img-grid cursor-pointer">
                  </div>
                  <div class="tooltip">
                    <div class="checkout-h2 enhance-grid-h2 text-truncate2">{{product.Description}}
                      <span class="tooltiptext">{{product.Description}}</span>
                    </div>
                  </div>
                  <h3 class="enhance-grid-price mh-22 ">LOYAL CUSTOMER PRICE :${{product.Price |number : '1.2-2'}} USD</h3>
                  <h3 class="enhance-grid-price mh-22 r-b">RETAIL PRICE : ${{product.Other1Price |number : '1.2-2'}} USD</h3>
                  <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59"
                    class="button enhance-grid-button w-button"
                    (click)="addToCart(product,1,2);">Add To Cart</a>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <summary-cart [routPath]="routPath" *ngIf="!_enrollSession.mobileView"></summary-cart>
    </div>
  </div>
</div>