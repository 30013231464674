import { NgModule, Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FilterPipeModule } from 'ngx-filter-pipe';

// import 'hammerjs';
import * as _ from 'underscore';
import { AppComponent } from 'app/app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
// import { LayoutModule } from 'app/layout/layout.module';

import { ToastrModule } from 'ngx-toastr';

import {PagesModule} from 'app/main/pages.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NotTranslatedService } from './nottranslate.service';
import { LoaderService } from './loader.service';
import { LoaderInterceptor } from './loader-interceptor.service';
import { MyLoaderComponent } from './main/my-loader/my-loader.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TodaysOrderComponent } from './main/Todays-order/Todays-order.component';
const appRoutes: Routes = [
    {
        path: 'TodaysOrder',
        component: TodaysOrderComponent
    },
    {
        path      : '',
        redirectTo: '/TodaysOrder',
        pathMatch: 'full',
    },
    {
        path      : '**',
        redirectTo: '/TodaysOrder',
        pathMatch: 'full',
    }
];
@Injectable()
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    if (params.interpolateParams) {
      return params.interpolateParams['Default'] || params.key;
    }
    return params.key;
  }

  constructor(private nts: NotTranslatedService) { }
}
@NgModule({
    declarations: [
      AppComponent,
      MyLoaderComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FlexLayoutModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
          }),
        RouterModule.forRoot(appRoutes, {
            enableTracing: false
        }),

        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
            },
            missingTranslationHandler: {
              provide: MissingTranslationHandler,
              useClass: MyMissingTranslationHandler,
              deps: [NotTranslatedService]
            }
          }),
        // InMemoryWebApiModule.forRoot(FakeDbService, {
        //     delay             : 0,
        //     passThruUnknownUrl: true
        // }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatExpansionModule,
        MatDialogModule,
        // Fuse modules
        // FuseModule.forRoot(fuseConfig),
        // FuseProgressBarModule,
        // FuseSharedModule,
        // FuseSidebarModule,

        // App modules
        PagesModule,
        // LayoutModule,
        FilterPipeModule,
        // MatDialogModule,
        // toaster
        ToastrModule.forRoot()
    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        LoaderService,
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    ]
})
export class AppModule
{
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
