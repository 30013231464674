// export const BASE_URL = "https://purium-api-dev.wsicloud.com";
export const BASE_URL = (location.hostname.search('wsicloud.net') > -1 || location.hostname.search('localhost') > -1) ? 'https://purium-api-dev.wsicloud.com' : 'https://api.puriumenrollment.com';
export const API_URL = `${BASE_URL}/api`;

export function objectToParams(object): string {
    function clean(obj): void {
        for (const propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    }
    clean(object);
    return Object.keys(object).map((key) => (typeof (object[key]) === 'object') ?
        subObjectToParams(encodeURIComponent(key), object[key]) :
        `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`
    ).join('&');
}

function subObjectToParams(key, object): string {
    return Object.keys(object).map((childKey) => (typeof (object[childKey]) === 'object') ?
        subObjectToParams(`${key}[${encodeURIComponent(childKey)}]`, object[childKey]) :
        `${key}[${encodeURIComponent(childKey)}]=${encodeURIComponent(object[childKey])}`
    ).join('&');
}
