import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { RestService } from './rest.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { EnrollSessionService } from './enrollSession.service';

@Injectable({
  providedIn: 'root'
})
export class GetDiscountedItemsService {
  // items: any;
  // discountedItem: any;
  // IsCallCalculateOrder: boolean = false;
  EnrollSession: any;
  discountItem: any
  constructor(
    private _toastr: ToastrService,
    private _router: Router,
    private _http: HttpClient,
    private route: ActivatedRoute,
    private _restService: RestService,
    private _enrollSession: EnrollSessionService) {
    // this.IsCallCalculateOrder = false;
    // this.items = ['11050-D', '11050-DD'];
    // this.discountedItem = { itemCode: '', qty: 0 };
    this.discountItem = { itemCode: '', qty: 0 };
    this._enrollSession.data = JSON.parse(sessionStorage.getItem('EnrollSession')) ? JSON.parse(sessionStorage.getItem('EnrollSession')) : {};
  }

  // getDiscountedItem(amount: any): any {
  //   this.discountedItem = { itemCode: '', qty: 0 };
  //   this._enrollSession.data.qualifyforFREE = false;
  //   if (amount >= 500 || this.ischeckFreeLaunchItem(this._enrollSession.data.RetailOrderSummary.InitialOrders) ||
  //     (this._enrollSession.data.EligibleForFreeUpgrade && this.ischeckFreeLaunchItem(this._enrollSession.data.RetailOrderSummary.Enrollpack))) {
  //     this.discountedItem.itemCode = '11050-DD';
  //     this.discountedItem.qty = -1;
  //     this._enrollSession.data.qualifyforFREE = true;
  //   }
  //   else if (amount >= 250 && amount < 500) {
  //     this.discountedItem.itemCode = '11050-D';
  //     this.discountedItem.qty = -1;
  //     this._enrollSession.data.qualifyforFREE = false;
  //   }
  //   return this.discountedItem;
  // }

  // ischeckFreeLaunchItem(InitialItem: any): any {
  //   const isItemExit = _.filter(InitialItem, (item: any) => {
  //     return item.ItemCode === '11208' || item.ItemCode === '11208-P65' || item.ItemCode === '11209'
  //       || item.ItemCode === '11209-AB' || item.ItemCode === '11209-AB-P65' || item.ItemCode === '12520'
  //       || item.ItemCode === '12520-P65' || item.ItemCode === '11207-P65' || item.ItemCode === '11207' || item.ItemCode === '11206-P65'
  //       || item.ItemCode === '11206' || item.ItemCode === '12135-L-P65' || item.ItemCode === '12135-L' || item.ItemCode === '12135'
  //       || item.ItemCode === '11216-CH' || item.ItemCode === '11216-P65' || item.ItemCode === '11216' || item.ItemCode === '11215'
  //       || item.ItemCode === '11215-P65' || item.ItemCode === '14600-P65' || item.ItemCode === '14600' || item.ItemCode === '11153-O-P65'
  //       || item.ItemCode === '11153-O' || item.ItemCode === '11153' || item.ItemCode === '11152-AB-P65' || item.ItemCode === '11152-AB'
  //       || item.ItemCode === '11152' || item.ItemCode === '11204-P65' || item.ItemCode === '11204' || item.ItemCode === '11155-P65'
  //       || item.ItemCode === '11155' || item.ItemCode === '11154-P65' || item.ItemCode === '11154' || item.ItemCode === '11144-AB-P65'
  //       || item.ItemCode === '11144-AB' || item.ItemCode === '11144' || item.ItemCode === '11200' || item.ItemCode === '12134'
  //       || item.ItemCode === '12132' || item.ItemCode === '12132-P65' || item.ItemCode === '11205' || item.ItemCode === '11205-AB'
  //       || item.ItemCode === '11205-AB-P65' || item.ItemCode === '11210' || item.ItemCode === '11210-P65'
  //       || item.ItemCode === '11220' || item.ItemCode === '11220-AB' || item.ItemCode === '11220-AB-P65'
  //       || item.ItemCode === '12136' || item.ItemCode === '12136-L' || item.ItemCode === '12136-L-P65'
  //       || (item.ItemCode === '11050-RU' && this._enrollSession.data.EligibleForFreeUpgrade);
  //   });
  //   if (isItemExit.length > 0) {
  //     return true;
  //   } else {
  //     this._enrollSession.data.qualifyforFREE = false;
  //     return false;
  //   }
  // }
  getDiscount(amount) {
    this.discountItem = { itemCode: '', qty: 0, Price: 0 };
      if (amount < 200 && amount >= 75) {
      this.discountItem.itemCode = '7800GC';
        this.discountItem.qty = 1;
        this.discountItem.Price = -50;
    }
    return this.discountItem;
  }

}
