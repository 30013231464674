import { Component, Inject, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { _ROUTER_CONFIG } from '@ngrx/router-store/src/router_store_module';
import * as _ from 'underscore';
import * as moment from 'moment';
import { EnrollSessionService } from './shared/enrollSession.service';


@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    templateUrl: any;
    screenWidth: any;
    mobileView: any;
    mediumScreen: any;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        // private _fuseConfigService: FuseConfigService,
        // private _fuseNavigationService: FuseNavigationService,
        // private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private ref: ChangeDetectorRef,
        public _enrollSession: EnrollSessionService
    ) {
        // this._translateService.setDefaultLang('en');
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        // this._translateService.use('en');

        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        setInterval(() => {
            sessionStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
            sessionStorage.setItem('submitApplicationData', JSON.stringify(this._enrollSession.submitApplicationData));
            sessionStorage.setItem('EnrollSession', JSON.stringify(this._enrollSession.data));
            sessionStorage.setItem('editTransformationPack', this._enrollSession.editTransformationPack); 
            // require view to be updated
            this.ref.markForCheck();
            
        }, 1000);

        

    }


    ngOnInit(): void {
        this.onResize();
        // Subscribe to config changes
        // this._fuseConfigService.config
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((config) => {

        //         this.fuseConfig = config;

        //         // Boxed
        //         if (this.fuseConfig.layout.width === 'boxed') {
        //             this.document.body.classList.add('boxed');
        //         }
        //         else {
        //             this.document.body.classList.remove('boxed');
        //         }

        //         // Color theme - Use normal for loop for IE11 compatibility
        //         for (const row of this.document.body.classList) {
        //             const className = row;

        //             if (className.startsWith('theme-')) {
        //                 this.document.body.classList.remove(className);
        //             }
        //         }

        //         this.document.body.classList.add(this.fuseConfig.colorTheme);
        //     });    
        // this.$watch('EnrollSession', function (new_value, old_value) {
        //     if (new_value !== old_value) {
        //         sessionStorage.setItem('EnrollSession', JSON.stringify(this.EnrollSession));
        //     }
        // }, true);

        // logCurrent = function(): any {
        //     $log.debug(this.EnrollSession);
        // };
    } 
    @HostListener('window:resize', ['$event'])
    onResize(): void {
      this.screenWidth = window.innerWidth;
      if (this.screenWidth < 550) {
          this.mobileView = true;
      }
      else {
          this.mobileView = false;
      }
      if (this.screenWidth < 1100) {
        this.mediumScreen = true;
    }
    else {
        this.mediumScreen = false;
    }
      localStorage.setItem('mobileView', this.mobileView)
    // sessionStorage.setItem('mobileView', this.mobileView)
    this._enrollSession.mobileView = this.mobileView;
    sessionStorage.setItem('MobileView', JSON.stringify(this._enrollSession.mobileView));
    this._enrollSession.mediumScreen = this.mediumScreen;
    sessionStorage.setItem('mediumScreen', JSON.stringify(this._enrollSession.mediumScreen));
  }   

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        // this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
    onActivate(event) {
        // window.scroll(0,0);
     
        window.scroll({ 
                top: 0, 
                left: 0, 
                behavior: 'smooth' 
         });
     
         //or document.body.scrollTop = 0;
         //or document.querySelector('body').scrollTo(0,0)
     }
}
