import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { EnrollSessionService } from '../enrollSession.service';

@Component({
  selector: 'subscribe-dialog',
  templateUrl: './subscribe-dialog.component.html',
  styleUrls: ['./subscribe-dialog.component.scss']
})
export class SubscribeDialogComponent implements OnInit {
  ngOnInit(): void {
     }
    
    constructor(
      public dialogRef: MatDialogRef<SubscribeDialogComponent>,
      public _enrollSession: EnrollSessionService,
      @Inject(MAT_DIALOG_DATA) public data: any) { }
  
    onSubscribe(SubscriptionStatus): void {
      // this._enrollSession.data.subscribeStatus = SubscriptionStatus;
      this.dialogRef.close(SubscriptionStatus);
    }
    

}
