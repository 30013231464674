import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import * as _ from 'underscore';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgpSortModule } from 'ngp-sort-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { TodaysOrderComponent } from './Todays-order.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SummaryCartModule } from '../summary-cart/summary-cart.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PipesModule } from '../pipe/pipe.module';
import { StepperModule } from '../stepper/stepper.module';
import { OrderSubItemComponent } from 'app/shared/Orders-Sub-Item/Orders-Sub-Item.component';
import { productInfoComponent } from 'app/shared/Product-info/Product-info.component';
import { AppComponent } from 'app/app.component';
import { LifestyleSubscriptionModule } from 'app/shared/LifestyleSubscription/LifestyleSubscription.module';
const routes: Routes = [
    {
        path: 'TodaysOrder',
        component: TodaysOrderComponent
    }
];

@NgModule({
    declarations: [
        TodaysOrderComponent,
        OrderSubItemComponent,
        productInfoComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatTableModule,
        MatTabsModule,
        NgpSortModule,
        NgxChartsModule,
        FilterPipeModule,
        MatExpansionModule,
        SummaryCartModule,
        MatTooltipModule,
        FlexLayoutModule,
        PipesModule,
        StepperModule,
        MatDialogModule,
        LifestyleSubscriptionModule
    ], entryComponents: [
        productInfoComponent,
        OrderSubItemComponent
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class TodaysOrderModule {
}

