import { Component, OnInit, Inject, ViewEncapsulation, Pipe, PipeTransform } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';



@Component({
    selector: 'app-product-info',
    templateUrl: './Product-info.component.html',
    styleUrls: ['./Product-info.component.scss'],
    encapsulation: ViewEncapsulation.None
    
})
export class productInfoComponent implements OnInit {
    customersForm: FormGroup;
    dialogTitle: string;
    action: string;
    userDetails: any;
    customerType: string;
    item: any;
    constructor(
        public matDialogRef: MatDialogRef<productInfoComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any
    ) {  // Set the defaults
        this.action = _data.action;
        // Set the private defaults
            this.dialogTitle = this._data.item.Description;
            this.item = this._data.item;
    }

    ngOnInit() {
        window.scrollTo(0, 0);
    }

}