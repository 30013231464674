import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-confirmation',
  templateUrl: './Confirmation.component.html',
  styleUrls: ['./Confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationComponent implements OnInit {
  cardNum: any;
  zoom = 14;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 50,
    minZoom: 4,
  };
  markers:any[] = [];
  latitude:any;
  longitude:any;
  constructor(
    public _enrollSession: EnrollSessionService,
    public router: Router
  ) { 

  }

  ngOnInit(): void {
    this._enrollSession.editTransformationPack = '';
    let num = this._enrollSession.data.RetailOrderSummary.paymentTypeData.cardNumber;
    this.cardNum = num.toString().slice(-4);
    var geocoder = new google.maps.Geocoder();
    var address1 = this._enrollSession.data.RetailOrderSummary.shippingFormData.address1;
    var address2 = this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 ? this._enrollSession.data.RetailOrderSummary.shippingFormData.address2 : '';
    var city = this._enrollSession.data.RetailOrderSummary.shippingFormData.City;
    var zipcode = this._enrollSession.data.RetailOrderSummary.shippingFormData.ZipCode;
    var state = this._enrollSession.data.RetailOrderSummary.shippingFormData.State;
    var country = this._enrollSession.data.RetailOrderSummary.shippingFormData.CountryCode;
    var address = address1+' '+address2+', '+city+', '+state+' '+zipcode+' '+country;
    var that = this;
    geocoder.geocode( { 'address': address}, function(results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        that.latitude = results[0].geometry.location.lat();
        that.longitude = results[0].geometry.location.lng();
        that.center = {
          lat: that.latitude,
          lng: that.longitude,
        }
       
      } 
    });
   
    setTimeout(() => {
      this.markers.push({
        position: {
          lat: that.latitude,
          lng: that.longitude,
        },
        options: { animation: google.maps.Animation.BOUNCE },
      });
    }, 3000);
    
   
  }
  zoomIn() {
    if (this.zoom < this.options.maxZoom) this.zoom++
  }

  zoomOut() {
    if (this.zoom > this.options.minZoom) this.zoom--
  }
  
  ngOnDestroy(): void
  {
    this._enrollSession.data = {};
    this._enrollSession.submitApplicationData = {};
    this._enrollSession.editTransformationPack = '';
    this.router.navigate(['/', 'TodaysOrder']);
  }
}
