import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as _ from 'underscore';
import { RestService } from 'app/shared/rest.service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { MatDialog } from '@angular/material/dialog';
import { productInfoComponent } from 'app/shared/Product-info/Product-info.component';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { OrderSubItemComponent } from 'app/shared/Orders-Sub-Item/Orders-Sub-Item.component';
import { any } from 'underscore';
import { LifestyleSubscriptionComponent } from 'app/shared/LifestyleSubscription/LifestyleSubscription.component';

@Component({
    selector: 'app-todays-order',
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    templateUrl: './Todays-order.component.html',
    styleUrls: ['./Todays-order.component.scss']
})
export class TodaysOrderComponent implements OnInit {
    productList: any[] = [];
    routPath: any = "Subscribe";
    EnhanceTranformation: any[] = [];
    onSearchEnhance:  any;
    panelOpenState : boolean = true;
    siteType: any;
    location: Location;
    url:any;
    webalias: any;

    constructor(
      
        private _restService: RestService,
        public _enrollSession: EnrollSessionService,
        public _sharedMethodService: SharedMethodService,
        private _matDialog: MatDialog,
        private router : Router,
        private _location: Location,
        private _toastr: ToastrService,
        private _activateRoute: ActivatedRoute,

    ) { 
        this._enrollSession.data.customertype = 22;
        this.location = this._location;
        if(this._enrollSession.editTransformationPack!= 'true') {
            this._enrollSession.data = {};
        }
        // localStorage.clear();
        if (this._enrollSession.data && this._enrollSession.editTransformationPack!= 'true' && Object.keys(this._enrollSession.data).length === 0 ) {
            this._enrollSession.data.IsShowCart = false;
            this._enrollSession.data.page = this.location.path();
            this._enrollSession.data.TotalItemLength = 0;
            this._enrollSession.data.RetailEnroll = {};
            this._enrollSession.data.InitOrder = [];
            this._enrollSession.data.InitialOrdersSubTotal = 0;
            this._enrollSession.data.RetailOrderSummary = {};
            this._enrollSession.data.RetailOrderSummary.Enrollpack = [];
            this._enrollSession.data.EligibleForFreeUpgrade = false;
            this._enrollSession.data.RetailEnroll.IsNonprofit = false;
            this._enrollSession.data.FileUploaded = false;
            this._enrollSession.data.RetailEnroll.ShippingMethod = '';
            this._enrollSession.data.usePrimaryCard = null;
            this._enrollSession.data.primaryCreditCardField = {};
            this._enrollSession.data.EnrollmentType = this._enrollSession.data.EnrollmentType || 1;
            this._enrollSession.data.IsUpGrade =
                this._activateRoute.snapshot.queryParamMap.get('customerId') ? this._activateRoute.snapshot.queryParamMap.get('customerId') : false;
            if (this._enrollSession.data.IsUpGrade) {
                localStorage.setItem('customerId', this._enrollSession.data.IsUpGrade);
            }
            this._enrollSession.data.Volume109 = 0;
            this._enrollSession.data.SmartOrderBVTotal = 0;
            this._enrollSession.data.InitialOrdersQVTotal = 0;
            this._enrollSession.data.AddOnOrdersQVTotal = 0;
        }
        this.url = window.location.href;
        console.log("url",this.url);
        this.webalias = this._activateRoute.snapshot.queryParamMap.get('giftcard');
        this.siteType = this._activateRoute.snapshot.queryParamMap.get('site');
        if (this.siteType) {
            sessionStorage.setItem("siteType", this.siteType);
            var snapshot = this._activateRoute.snapshot;
            const params = { ...snapshot.queryParams };
            delete params.site
            this.router.navigate([], { queryParams: params });
        } else {
            this.siteType = sessionStorage.getItem("siteType");
        }
        window.scrollTo(0, 0);
        // if((this._enrollSession.data.RetailOrderSummary == undefined) || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.Enrollpack.length == 0)){
        //     if(this._enrollSession.data.RetailOrderSummary.Enrollpack.length == 0) {
        //         this._toastr.warning("Please select Transformation Pack", 'Warning');
        //     }
        //     this.router.navigate(['/TodaysOrder']);
        // }
    }

    ngOnInit(): void {
        if(this.webalias && this.webalias != '' && this.webalias != null && this.webalias != "null") {
            const request = '?WebAlias=' + this.webalias;
            this._restService.GetCustomerSite(request).subscribe((result: any) => {
                if(result.Data != null) {
                    this._enrollSession.data.webAlias = this.webalias;
                }else{
                    this._toastr.error('Invalid WebAlias.', 'Error');
                }
            }, error => {
                this._toastr.error('Somthing is wrong Please try again', 'Error');
            });
        }
        this._enrollSession.editTransformationPack = "true";
        this.siteType = sessionStorage.getItem("siteType");
        if (this.siteType == 'collagen') {
            this.getAllProduct(495);
        }
        else {
            this.getAllProduct(482);
            this.getAllProduct(472)
        }
    }

    getAllProduct(webCategoryId) {
        const request = {
            'EnrollmentType': '',
            'CustomerType': 22,
            'WebCategoryId': webCategoryId,
            'isUpgradeCustomer': false,
            'IsSubscriptionOrder': false

        };
        this._restService.getProducts(request).subscribe((res: any) => {
            var listData = res.Items;
            var uniqueproductListData = listData.filter((tag, index, array) => array.findIndex(t => t.ItemCode == tag.ItemCode && t.Description == tag.Description && t.Price == tag.Price) == index);
            //this.productList = uniqueproductListData;
            var allProductList = uniqueproductListData;
           
            //var EnhanceTranformation = [];
            allProductList.forEach((item) => {
                if (item.CategoryID === 482) {
                    this.EnhanceTranformation.push(item);
                }
                if (item.CategoryID === 495) {
                    this.EnhanceTranformation.push(item);
                }
                if (item.CategoryID === 472) {
                    this.productList.push(item);
                }
            });
            //let enhanceTransformProducts = cloneDeep(EnhanceTranformation);
            //enhanceTransformProducts.forEach((item) => {
            //    //if(item.ItemCode ==2520) {
            //    //    item.SmallPicture = "assets/images/enhance-image/immunesupport-2520.png";
            //    //}else if(item.ItemCode ==2750) {
            //    //    item.SmallPicture = "assets/images/enhance-image/energy-boost-2750.png";
            //    //}else if(item.ItemCode ==2752) {
            //    //    item.SmallPicture = "assets/images/enhance-image/digestion-2752.png";
            //    //}else if(item.ItemCode ==2754) {
            //    //    item.SmallPicture = "assets/images/enhance-image/hydration-2754.png";
            //    //}else if(item.ItemCode == 2012) {
            //    //    item.SmallPicture = "assets/images/enhance-image/elimination-2012.png";
            //    //}
            //});
            //if (webCategoryId === 482) {
            //    this.EnhanceTranformation = enhanceTransformProducts;
            //}
        });
    }
    productInfo(item) {
        const dialogRef = this._matDialog.open(productInfoComponent, {
            width: '650px',

            panelClass: 'product-info',
            data: {
                item: item
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == 'Success') {
            }
        });
    }
    onSearchChangeEnhance(event) {
        this.onSearchEnhance = event.target.value;
    }
    addToCart(item: any, quantity: any,type: any){
        if((item.ItemCode=='11200' || item.ItemCode=='11205' || item.ItemCode=='11235' || item.ItemCode=='11238' || item.ItemCode=='11208' || item.ItemCode=='11209' || item.ItemCode=='11210' || item.ItemCode=='11220')) {
            this.ultlifestylePoup(item.ItemCode);
        }
        if (item.KitMembers && item.KitMembers.length > 0) {
            const dialogRef = this._matDialog.open(OrderSubItemComponent, {
                width: '600px',
                panelClass: ['order-sub-item', 'sub-item-modal'],
                data: {
                    item: item,
                    type:type,
                    
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                
            });
        }
        else{
            console.log('addToCart');
            this._sharedMethodService.ContinueRetailOrder(item,quantity,type)
        }
    }
    ultlifestylePoup(itemCode)
    {
        const dialogRef = this._matDialog.open(LifestyleSubscriptionComponent, {
            width: '500px',
            maxWidth: '500px',
            height: '356px',
            panelClass: '',
            disableClose: true,
            data: {
                item: itemCode,
            }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }
}