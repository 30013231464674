import { NgModule } from '@angular/core';
import { SearchPipe } from './search-pipe';

@NgModule({
    declarations: [
        SearchPipe
    ],
    imports     : [],
    exports     : [
        SearchPipe
    ]
})
export class PipesModule
{
}
