import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
// import { MatDialog } from '@angular/material/dialog';
import { RestService } from 'app/shared/rest.service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SharedMethodService } from 'app/shared/sharedMethod.service';
import { EnrollSessionService } from 'app/shared/enrollSession.service';
import { productInfoComponent } from 'app/shared/Product-info/Product-info.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrderSubItemComponent } from 'app/shared/Orders-Sub-Item/Orders-Sub-Item.component';

@Component({
    selector: 'Subscribe',
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    templateUrl: './Subscribe.component.html',
    styleUrls: ['./Subscribe.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SubscribeComponent implements OnInit {
    productList: any[] = [];
    topPicks: any[] = [];
    alternates: any[] = [];
    freeProducts: any[] = [];
    smarOrderallProductList: any[] = [];
    routPath: any = "Shipping";
    onSearch:  any;
    panelOpenState : any;
    siteType: any;
    // mobileView: any;
    // subscribeDialogMobile: Subject<any> = new Subject<any>();
    constructor(
        private _toastr: ToastrService,
        private _restService: RestService,
        public _sharedMethodService: SharedMethodService,
        public _enrollSession: EnrollSessionService,
        private _matDialog: MatDialog,
        private router : Router
    ) {
        this.siteType = sessionStorage.getItem("siteType");
        if(((this._enrollSession.data.RetailOrderSummary == undefined) || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.Enrollpack.length == 0)) &&  (this.siteType || this.siteType!=null)){
            if(this._enrollSession.data.RetailOrderSummary.Enrollpack.length == 0) {
                this._toastr.warning("Please select Transformation Pack", 'Warning');
            }
            this.router.navigate(['/TodaysOrder']);
        }
        if((this._enrollSession.data.RetailOrderSummary == undefined) ||  this._enrollSession.data.RetailOrderSummary.InitialOrders==undefined || (this._enrollSession.data.RetailOrderSummary && this._enrollSession.data.RetailOrderSummary.InitialOrders?.length == 0)){
            this._toastr.warning("Please select an item", 'Warning');
        this.router.navigate(['/TodaysOrder']);
       }
     }

    ngOnInit(): void {
        this.getAllProduct();
        this.getSmartOrderAllProduct();
    }
    getAllProduct() {
        const request = {
            'EnrollmentType': '',
            'CustomerType': '',
            'WebCategoryId': 466,
            'isUpgradeCustomer': false,
            'IsSubscriptionOrder': true

        };
        this._restService.getProducts(request).subscribe((res: any) => {
            if (res) {
                const TopPick = [];
                const Alternate = [];
                const FreeProducts = [];
                this.productList = res.Items;
                this.productList.forEach((item) => {
                    if (item.CategoryID === 473) {
                        TopPick.push(item);
                    } else if (item.CategoryID === 474) {
                        Alternate.push(item);
                    } else if (item.CategoryID === 475) {
                        FreeProducts.push(item);
                    }
                });
                this.topPicks = TopPick;
                this.alternates = Alternate;
                this.freeProducts = FreeProducts;
            } else {
                this._toastr.error('Error');
            }
        }, error => {
            this._toastr.error(error.error.Message, 'Error');
        });
    }
    getSmartOrderAllProduct() {
        const request = {
            'EnrollmentType': '',
            'CustomerType': '',
            'WebCategoryId': 472,
            'isUpgradeCustomer': false,
            'IsSubscriptionOrder': true

        };
        this._restService.getProducts(request).subscribe((res: any) => {
            if (res) {
                 this.smarOrderallProductList = res.Items;
            } else {
                this._toastr.error('Error');
            }
        }, error => {
            this._toastr.error(error.error.Message, 'Error');
        });
    }
    productInfo(item) {
        const dialogRef = this._matDialog.open(productInfoComponent, {
            width: '650px',

            panelClass: 'product-info',
            data: {
                item: item
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == 'Success') {
            }
        });
    }
    onSearchChange(event) {
        this.onSearch = event.target.value;
    }

    addToCart(item: any, quantity: any,type: any){
        if (item.KitMembers && item.KitMembers.length > 0) {
            const dialogRef = this._matDialog.open(OrderSubItemComponent, {
                width: '600px',
                panelClass: ['order-sub-item', 'sub-item-modal'],
                data: {
                    item: item,
                    type:type,
                    
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                
            });
        }
        else{
            this._sharedMethodService.ContinueRetailOrder(item,quantity,type)
        }
    }
}



