import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RgetRegexService {


    constructor() { }
    getRegex(countryCode: any): any {
        let regex: any;
        switch (countryCode) {
            case 'AR':
                //  regex = /^([A-HJ-TP-Z]{1}\d{4}[A-Z]{3}|[a-z]{1}\d{4}[a-hj-tp-z]{3})$/;
                regex = /^[a-zA-Z0-9 _.-]*$/;
                break;
            case 'AT':
            case 'AU':
            case 'BE':
            case 'HU':
            case 'NZ':
            case 'PH':
                regex = /^\d{4}$/;
                break;
            case 'CR':
            case 'DE':
            case 'ES':
            case 'FR':
            case 'MX':
            case 'PE':
                regex = /^\d{5}$/;
                break;
            case 'PA':
            case 'RU':
            case 'EC':
            case 'SG':
            case 'IN':
                regex = /^\d{6}$/;
                break;
            case 'JP':
                regex = /^\d{7}$/;
                break;
            case 'CA':
                regex = /^([ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]\d[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz])\ {0,1}(\d[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz]\d)$/;
                break;
            case 'CL':
                regex = /^(\d{7}|\d{3}[-]\d{4})$/;
                break;
            case 'KR':
                regex = /^(\d{6}|\d{3}[-]\d{3})$/;
                break;
            case 'NL':
                regex = /^[1-9][0-9]{3}\s?([a-zA-Z]{2})?$/;
                break;
            case 'PT':
                regex = /^\d{4}([\-]?\d{3})?$/;
                break;
            case 'US':
                regex = /^\d{5}([\-]?\d{4})?$/;
                break;
            case 'GB':
                // regex = /^([g][i][r][0][a][a])$|^((([a-pr-uwyz]{1}([0]|[1-9]\d?))|([a-pr-uwyz]{1}[a-hk-y]{1}([0]|[1-9]\d?))|([a-pr-uwyz]{1}[1-9][a-hjkps-uw]{1})|
                // ([a-pr-uwyz]{1}[a-hk-y]{1}[1-9][a-z]{1}))(\d[abd-hjlnp-uw-z]{2})?)$/i;
                // regex = /^ ?(([BEGLMNSWbeglmnsw][0-9][0-9]?)|(([A-PR-UWYZa-pr-uwyz]
                // [A-HK-Ya-hk-y][0-9][0-9]?)|(([ENWenw][0-9][A-HJKSTUWa-hjkstuw])|
                // ([ENWenw][A-HK-Ya-hk-y][0-9][ABEHMNPRVWXYabehmnprvwxy])))) ?[0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}$/;
                // regex = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|
                // (([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/;
                regex = /^([A-Pa-pR-Ur-uWYZwyz](([0-9](([0-9]|[A-Ha-hJKSTUWjkstuw])?)?)|([A-Ha-hK-Yk-y][0-9]([0-9]|[ABEHMNPRVWXYabehmnprvwxy])?)) [0-9][ABabD-Hd-hJLNjlnP-Up-uW-Zw-z]{2})|GIRgir 0AAaa$/;

                break;
            default:
                regex = /^[a-zA-Z0-9 _.-]*$/;
                break;
        }
        return regex;
    }

}
