<div class="page-wrapper subscribe">
  <div class="ult-checkout-section wf-section">
    <app-stepper *ngIf="_enrollSession.mobileView"></app-stepper>
    <summary-cart *ngIf="_enrollSession.mobileView" [routPath]="routPath"></summary-cart>
    <div class="checkout-div">
      <app-stepper *ngIf="!_enrollSession.mobileView"></app-stepper>
      <h1 class="checkout-h1 smart-order-h1-copy">Subscribe &amp; Save</h1>
      <h1 class="checkout-h1 smart-order-h1">Get Long-Term Results &amp; Max Savings with Smart Order</h1>
      <h1 class="smart-order-p">What will your nutrition needs be after your first order? Whether it’s fitness goals, weight loss, or fine-tuning your body, we will reward you for making your plan today!</h1>
      <h1 class="smart-order-h2">How It Works:</h1>
      <ol role="list" class="smart-order-list">
        <li class="smart-order-list-item">Choose a product or pack below.</li>
        <li class="smart-order-list-item">Choose a date you would like us to charge your credit card every month.</li>
        <li class="smart-order-list-item">Enjoy superfoods automatically shipped to your  doorstep!</li>
      </ol>
      <h1 class="smart-order-h2">Smart Order Rewards:</h1>
      <ul role="list" class="smart-order-list smart-order-rewards-list">
        <li class="smart-order-list-item">Earn double (2x) Rewards Points on every order.</li>
        <li class="smart-order-list-item">Get 200 Bonus Rewards Points today.</li>
        <li class="smart-order-list-item">Ensure your 25% by ordering every 30 days.</li>
        <li class="smart-order-list-item">Qualify for a free product in your 4th month with 3 &amp; Then Free Products.</li>
      </ul>
      <h1 class="checkout-h1 smart-order-h1-choose">Choose a product or pack below</h1>
      <h1 class="checkout-h2 top-picks-h2">Top Picks</h1>
      <div class="w-layout-grid enhance-grid cen m-b-30" *ngIf="_enrollSession.mobileView">
        <div *ngFor="let topPick of topPicks" class="m-b-30">
          <div class="itemBorder" (click)="productInfo(topPick)">
          <img src="{{topPick.SmallPicture}}"  loading="lazy" class="checkout-product-img checkout-product-img-grid cursor-pointer">
          </div>
          <div class="tooltip">
            <div class="checkout-h2 enhance-grid-h2 text-truncate">{{topPick.Description}}
              <span class="tooltiptext">{{topPick.Description}}</span>
            </div>
          </div>
          <div class="enhance-grid-price mh-22">LOYAL CUSTOMER PRICE : ${{topPick.Price |number : '1.2-2'}} USD</div>
          <div class="enhance-grid-price mh-22 r-b">RETAIL PRICE : ${{topPick.Other1Price |number : '1.2-2'}} USD</div>
          <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{topPick.ShortDetail3}}</div>
          <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59" class="button enhance-grid-button w-button"  (click)="addToCart(topPick,1,3)">Subscribe</a>
        </div>
      </div>
      <div class="w-layout-grid enhance-grid" *ngIf="!_enrollSession.mobileView">
        <div *ngFor="let topPick of topPicks" class="m-b-30">
          <div class="itemBorder" (click)="productInfo(topPick)">
            <img src="{{topPick.SmallPicture}}" loading="lazy" class="checkout-product-img checkout-product-img-grid cursor-pointer">
          </div>
          <div class="tooltip">
            <div class="checkout-h2 enhance-grid-h2 text-truncate">{{topPick.Description}}
              <span class="tooltiptext">{{topPick.Description}}</span>
            </div>
          </div>
          <!-- <div class="enhance-grid-price p-16">${{topPick.Price |number : '1.2-2'}} USD</div> -->
          <div class="enhance-grid-price mh-22">LOYAL CUSTOMER PRICE : ${{topPick.Price |number : '1.2-2'}} USD</div>
          <div class="enhance-grid-price mh-22 r-b">RETAIL PRICE : ${{topPick.Other1Price |number : '1.2-2'}} USD</div>
          <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{topPick.ShortDetail3}}</div>
          <a class="button enhance-grid-button w-button"  (click)="addToCart(topPick,1,3)">Subscribe</a>
        </div>
      </div>
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h1 class="order-summary-h1 all-products-accordion">Alternatives</h1>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="w-layout-grid enhance-grid" *ngIf="!_enrollSession.mobileView">
            <div *ngFor="let alternate of alternates" class="m-b-30">
              <div class="itemBorder" (click)="productInfo(alternate)">
                <img src="{{alternate.SmallPicture}}" loading="lazy" class="checkout-product-img checkout-product-img-grid cursor-pointer">
              </div>
              <div class="tooltip">
                <div class="checkout-h2 enhance-grid-h2 text-truncate">{{alternate.Description}}
                  <span class="tooltiptext">{{alternate.Description}}</span>
                </div>
              </div>
              <div class="enhance-grid-price mh-22">LOYAL CUSTOMER PRICE : ${{alternate.Price |number : '1.2-2'}} USD</div>
              <div class="enhance-grid-price mh-22 r-b">RETAIL PRICE : ${{alternate.Other1Price |number : '1.2-2'}} USD</div>
              <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{alternate.ShortDetail3}}</div>
              <a class="button enhance-grid-button w-button"  (click)="addToCart(alternate,1,3)">Subscribe</a>
            </div>
          </div>
          <div class="w-layout-grid enhance-grid cen" *ngIf="_enrollSession.mobileView">
            <div *ngFor="let alternate of alternates" class="m-b-30">
              <div class="itemBorder" (click)="productInfo(alternate)">
              <img src="{{alternate.SmallPicture}}"  loading="lazy" class="checkout-product-img checkout-product-img-grid cursor-pointer">
              </div>
              <div class="tooltip">
                <div class="checkout-h2 enhance-grid-h2 text-truncate">{{alternate.Description}}
                  <span class="tooltiptext">{{alternate.Description}}</span>
                </div>
              </div>
              <!-- <div class="enhance-grid-price p-16">${{alternate.Price |number : '1.2-2'}} USD</div> -->
              <div class="enhance-grid-price mh-22">LOYAL CUSTOMER PRICE : ${{alternate.Price |number : '1.2-2'}} USD</div>
              <div class="enhance-grid-price mh-22 r-b">RETAIL PRICE : ${{alternate.Other1Price |number : '1.2-2'}} USD</div>
              <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{alternate.ShortDetail3}}</div>
              <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59" class="button enhance-grid-button w-button"  (click)="addToCart(alternate,1,3)">Subscribe</a>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h1 class="order-summary-h1 all-products-accordion">3 & Then Free Products</h1>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="w-layout-grid enhance-grid" *ngIf="!_enrollSession.mobileView">
            <div *ngFor="let freeProduct of freeProducts" class="m-b-30">
              <div class="itemBorder" (click)="productInfo(freeProduct)">
                <img src="{{freeProduct.SmallPicture}}" loading="lazy" class="checkout-product-img checkout-product-img-grid cursor-pointer">
              </div>
              <div class="tooltip">
                <div class="checkout-h2 enhance-grid-h2 text-truncate">{{freeProduct.Description}}
                  <span class="tooltiptext">{{freeProduct.Description}}</span>
                </div>
              </div>
              <div class="enhance-grid-price mh-22">LOYAL CUSTOMER PRICE : ${{freeProduct.Price |number : '1.2-2'}} USD</div>
              <div class="enhance-grid-price mh-22 r-b">RETAIL PRICE : ${{freeProduct.Other1Price |number : '1.2-2'}} USD</div>
              <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{freeProduct.ShortDetail3}}</div>
              <a class="button enhance-grid-button w-button"  (click)="addToCart(freeProduct,1,3)">Subscribe</a>
            </div>
          </div>
          <div class="w-layout-grid enhance-grid cen" *ngIf="_enrollSession.mobileView">
            <div *ngFor="let freeProduct of freeProducts" class="m-b-30">
              <div class="itemBorder" (click)="productInfo(freeProduct)">
              <img src="{{freeProduct.SmallPicture}}"  loading="lazy" class="checkout-product-img checkout-product-img-grid cursor-pointer">
              </div>
              <div class="tooltip">
                <div class="checkout-h2 enhance-grid-h2 text-truncate">{{freeProduct.Description}}
                  <span class="tooltiptext">{{freeProduct.Description}}</span>
                </div>
              </div>
              <div class="enhance-grid-price mh-22">LOYAL CUSTOMER PRICE : ${{freeProduct.Price |number : '1.2-2'}} USD</div>
              <div class="enhance-grid-price mh-22 r-b">RETAIL PRICE : ${{freeProduct.Other1Price |number : '1.2-2'}} USD</div>
              <div class="checkout-h3 enhance-grid-h3 p-l-r-b" *ngIf="false">{{freeProduct.ShortDetail3}}</div>
              <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59" class="button enhance-grid-button w-button"  (click)="addToCart(freeProduct,1,3)">Subscribe</a>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel  (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header class="allProductHeader">
            <mat-panel-title>
              <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="start" fxLayoutAlign.gt-xs="space-between none" fxFlex>
                  <div fxLayoutAlign="start center" fxFlex="100" fxFlex.gt-md="49">
                    <h1 class="order-summary-h1 all-products-accordion">All Purium Products</h1>
                  </div>
                  <div *ngIf="panelOpenState" fxLayoutAlign="end center" fxLayoutAlign.lt-lg="center start" fxFlex="100" fxFlex.gt-md="49" class="searchBox" (keydown)="$event.stopImmediatePropagation();" (click)="$event.stopPropagation();">
                    <input class="searchInput" type="text"  (input)="onSearchChange($event)" placeholder="Search Your Product" fxFlex>
                    <mat-icon matSuffix>search</mat-icon>
                  </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="all-products-accordion-item">
            <div *ngIf="!_enrollSession.mediumScreen">
              <div *ngFor = "let product of smarOrderallProductList | sortBy: 'asc':'Description' | search: 'Description':onSearch">
                <div class="w-layout-grid all-products-grid grid">
                  <div class="free-oder" (click)="productInfo(product)">
                    <img src="{{product.SmallPicture}}" loading="lazy" id="w-node-_421021f4-112c-5413-f715-320b54c43fe5-db337a59" alt="" class="all-products-placeholder-img cursor-pointer">
                  </div>
                  <div class="tooltip align-center">
                    <div id="w-node-_97ec39c2-7213-1023-164a-ea6b31ad721f-db337a59" class="all-products-product-name text-truncate-all-prod">
                      {{product.Description}}<span class="tooltiptext">{{product.Description}}</span>
                    </div>
                  </div>
                  <div id="w-node-cd5718ae-6526-5955-b798-aee0dccf7998-db337a59" class="all-products-product-price prod-price" >
                    <div >LOYAL CUSTOMER PRICE : ${{product.Price |number : '1.2-2'}} USD</div>
                    <div class="r-b">RETAIL PRICE : ${{product.Other1Price |number : '1.2-2'}} USD</div>
                  </div>
                  <a id="w-node-_263a773b-b3b9-3901-44ef-9e82a85a78f1-db337a59" class="button enhance-grid-button w-button" (click)="addToCart(product,1,3)">Add To Cart</a>
                </div>
               </div>
            </div>
            <div class="w-layout-grid enhance-grid enhance-grid-mobile" *ngIf="_enrollSession.mediumScreen">
              <div *ngFor = "let product of smarOrderallProductList | sortBy: 'asc':'Description' | search: 'Description':onSearch">
                <div class="itemBorder" (click)="productInfo(product)">
                <img src="{{product.SmallPicture}}"  loading="lazy" class="checkout-product-img checkout-product-img-grid cursor-pointer">
                </div>
                <div class="tooltip">
                  <div class="checkout-h2 enhance-grid-h2 text-truncate">{{product.Description}}
                    <span class="tooltiptext">{{product.Description}}</span>
                  </div>
                </div>
                <h3 class="enhance-grid-price mh-22 ">LOYAL CUSTOMER PRICE :${{product.Price |number : '1.2-2'}} USD</h3>
                <h3 class="enhance-grid-price mh-22 r-b">RETAIL PRICE : ${{product.Other1Price |number : '1.2-2'}} USD</h3>
                <a id="w-node-d1025827-125d-8cf9-12a6-1ad93c74399f-db337a59" class="button enhance-grid-button w-button m-b-10" (click)="addToCart(product,1,3)">Add To Cart</a>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <summary-cart *ngIf="!_enrollSession.mobileView" [routPath]="routPath"></summary-cart>
  </div>
</div>