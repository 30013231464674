import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as _ from 'underscore';
import { EnrollSessionService } from 'app/shared/enrollSession.service';




@Component({
    selector: 'app-countrychange',
    templateUrl: './Countrychange.component.html',
    styleUrls: ['./Countrychange.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class CountrychangeComponent implements OnInit {
    dialogTitle: string;
    action: string;
    item: any;
    constructor(
        private _enrollSession: EnrollSessionService,
        public matDialogRef: MatDialogRef<CountrychangeComponent>
    ) {
    }

    Action(action: string): any {
        this.matDialogRef.close(action);        
    }
    ngOnInit(): void {
    }
}
