import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import * as _ from 'underscore';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgpSortModule } from 'ngp-sort-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { SummaryCartComponent } from './summary-cart.component';
import { SubscribeDialogModule } from 'app/shared/subscribe-dialog/subscribe-dialog.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    SummaryCartComponent
  ],
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatTabsModule,
    NgpSortModule,
    NgxChartsModule,
    FilterPipeModule,
    MatExpansionModule,
    MatDialogModule,
    SubscribeDialogModule,
    FlexLayoutModule
  ],
  entryComponents: [

  ],
  providers: [],
  exports: [
    SummaryCartComponent
  ]
})
export class SummaryCartModule { }
